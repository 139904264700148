import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_ESPLETATO_OK } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDolEspletatoOkState extends EntityState<IID_OL_ESPLETATO_OK, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_ESPLETATO_OK })
export class IIDolEspletatoOkStore extends EntityStore<IIDolEspletatoOkState> {
  constructor() {
    super([]);
  }
}