import { IIssue } from "@common/interfaces/issue";
import { IssueUI } from "@ep-om/project/state/issue/issue.store";

export const sortByCreatedAt = (a: { createdAt: string }, b: { createdAt: string }) => (sort: Function) => sort(new Date(a.createdAt).valueOf(), new Date(b.createdAt).valueOf());
export const sortByDueDate = (a: { dueDate: string }, b: { dueDate: string }) => (sort: Function) => sort(new Date(a.dueDate).valueOf(), new Date(b.dueDate).valueOf());
export const sortByUpdatedAt = (a: { updatedAt: string }, b: { updatedAt: string }) => (sort: Function) => sort(new Date(a.updatedAt).valueOf(), new Date(b.updatedAt).valueOf());
export const sortByListPosition = (a: IIssue & { ui: IssueUI }, b: IIssue & { ui: IssueUI }) => (sort: Function) => sort(a.ui.listPosition, b.ui.listPosition);

export const ascSort = (a: any, b: any) => a - b;
export const descSort = (a: any, b: any) => b - a;
