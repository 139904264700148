import { Inject, Injectable } from "@angular/core";
import { IIssue } from "@common/interfaces/issue";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { ActiveState, Constructor, EntityState, EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";
import { InterfaceName, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { LocalStorageService } from "@ep-om/project/service/localstorage.service";
import { STORAGE_INFO_KEY, StorageInfo } from "@ep-om/app.module";

export type PendingState = "done" | "pending";

export type IssueUI = {
  id: ID,
  listPosition: number,
  performedBy?: ID,
  pendingState?: PendingState
  openedInterface?: InterfaceNameValue[];
  //lastUpdatedByMe?: boolean;
}

export interface IssuesState extends EntityState<IIssue, ID>, ActiveState { }
export interface IssuesUIState extends EntityState<IssueUI, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUES })
export class IssueStore extends EntityStore<IssuesState> {

  private storageInfoInitialized = false;

  ui: EntityUIStore<IssuesUIState>
  constructor(
    private storageService: LocalStorageService,
    @Inject(STORAGE_INFO_KEY) private storageInfoKey: string,
  ) {
    super();
    this.createUIStore().setInitialEntityState({ listPosition: 0, news: true, pendingState: "done", openedInterface: [] });
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        currentInfo = currentInfo || [];
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: false })
        }
        return currentInfo;
      }
    )
  }

  upsertMany(entities: IIssue[], options?: { baseClass?: Constructor; loading?: boolean; }): void {
    super.upsertMany(entities, options);
    if (this.storageInfoInitialized) {
      return;
    }
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: true })
        } else {
          currentInfo[storeIdx].hasValue = true;
        }
        return currentInfo;
      }
    )
    this.storageInfoInitialized = true;
  }

  reset(): void {
    super.reset();
    this.storageInfoInitialized = false;
  }
}
