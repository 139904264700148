import { Injectable } from "@angular/core";
import { ID } from '@common/interfaces/id';
import { IID_GR_RESOURCES_MAP } from "@common/interfaces/interfaceData";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDGrResourcesMapState extends EntityState<IID_GR_RESOURCES_MAP, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'IID_GR_Resources_Map' })
export class IIDGrResourcesMapStore extends EntityStore<IIDGrResourcesMapState> {

  constructor() {
    super([]);
  }

}
