import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { EntityNameType, IEntityInteraction } from "@common/interfaces/entityInteraction";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface EntityInteractionState extends EntityState<IEntityInteraction, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.ENTITY_INTERACTIONS })
export class EntityInteractionStore extends EntityStore<EntityInteractionState> {
  constructor() {
    super([]);
  }

  generateID(userId:ID, issueId:ID, entityName: EntityNameType) {
    return `${userId}_${issueId}_${entityName}`;
  }

}
