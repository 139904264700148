import { Injectable } from "@angular/core";
import { IID_GENERIC_KO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IID_Generic_KoStore } from "./genericKo.store";

@Injectable({
  providedIn: 'root'
})
export class IID_Generic_KoQuery extends AbstractInterfaceDataQuery<IID_GENERIC_KO> {
  constructor(protected store: IID_Generic_KoStore) {
    super(store);
  }
}
