import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})
export class SingleComponent extends FieldWrapper {
  collapsed = true;

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
  
}
