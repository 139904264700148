import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "@ep-om/app.service";
import { AuthQuery } from "@ep-om/project/auth/auth.query";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(public authQuery:AuthQuery, private appService:AppService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const whitelist: string[] = []; // FV#25/10/2023: metto in whitelist gli url dove non devo inniettare il token principale
      if(this.appService.SETTINGS.documentationApi) whitelist.push(this.appService.SETTINGS.documentationApi.url);
      
      if (!whitelist.some(url => req.url.startsWith(url))) {
        const authState = this.authQuery.getValue();
        if (authState && authState.accessToken) {
          req = req.clone({ setHeaders: { Authorization: `Bearer ${authState.accessToken}` }});
        } 
      }
      
      return next.handle(req);
    } 
    
}
