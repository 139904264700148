import { Injectable } from "@angular/core";
import { IID_OL_CHECKLIST } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolChecklistStore } from "./iidOlChecklist.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDolChecklistQuery extends AbstractInterfaceDataQuery<IID_OL_CHECKLIST> {
  
    constructor(protected store: IIDolChecklistStore) {
      super(store);
    }
  }