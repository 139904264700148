import { Injectable } from "@angular/core";
import { IIDFaultManagementStore } from "./iidFaultManagement.store";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IID_FAULT_MANAGEMENT } from "@common/interfaces/interfaceData";

@Injectable({
  providedIn: 'root'
})
export class IIDFaultManagementQuery extends AbstractInterfaceDataQuery<IID_FAULT_MANAGEMENT> {

  constructor(protected store: IIDFaultManagementStore) {
    super(store);
  }

}
