import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_ANNULLAMENTO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolAnnullatoQuery } from "./annullato.query";
import { IIDolAnnullatoStore } from "./annullato.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAnnullatoService extends CrudService<IID_OL_ANNULLAMENTO, IIDolAnnullatoStore, IIDolAnnullatoQuery> {
  constructor(
    protected store: IIDolAnnullatoStore,
    public query: IIDolAnnullatoQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ANNULLATO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  getByIssueId(id: ID) {
    return this.query.getAll({
      filterBy: [
        entity => entity.issueId === id,
        entity => !entity.deletedAt,
      ]
    })[0];
  }

  getByIssueId$(id: ID) {
    return this.query.selectEntity(({ issueId }) => issueId === id);
  }
}
