


<div class="mb-6">
  <nz-form-label [nzSm]="6" [nzXs]="24" [nzFor]="formControl.value" [nzRequired]="this.field.props?.required">{{ to.label }}</nz-form-label>
  <div class="container">
    <span *ngIf="formControl.value === 'none'; else icon" class="icon-preview" nz-icon nzType="stop" nzTheme="outline"></span>
    <ng-template #icon>
      <svg-icon class="icon-preview" [size]="32"  [name]="formControl.value"></svg-icon>
    </ng-template>
    <nz-select class="w-full" [nzDisabled]="this.props.disabled" [formControl]="formControl">
      <nz-option *ngFor="let item of this.props.options" [nzValue]="item.value" [nzLabel]="item.label" ></nz-option>
    </nz-select>
  </div>
</div>
