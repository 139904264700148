import { Injectable } from "@angular/core";
import { IDataSource } from "./datasource.abstract";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { UserQuery } from "../state/user/user.query";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'any',
})
export class UserDataSource implements IDataSource {
  public readonly name = 'USER';
  constructor(private userQuery: UserQuery) {}
  getOptions$(): Observable<{ value: string; label: string; source: any; }[]> {
    return this.userQuery.selectAll().pipe(
      map(users => users.map(u => ({value: u.id, label: u.name, source: u })))
    );
  }
}
