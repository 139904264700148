import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent extends FieldType {
  @Input() listOfOption : Array<{ value: string; label: string }> = [];
  @Input() listOfSelectedValue = [];
  @Input() size: NzSelectSizeType = 'default';
}
