import { of } from "rxjs";
import { IDataSource } from "./datasource.abstract";
import { map } from "rxjs/operators";

export class PlayersDataSource implements IDataSource {
  public readonly name = 'PLAYERS';
  private _options = [{ id:'j1', name:'Gatti', team:'juve' },{ id:'j2', name:'Bremer', team:'juve' },{ id:'j3', name:'Fagioli', team:'juve' },{ id:'r1', name:'Pellegrini', team:'roma' },{ id:'i1', name:'Barella', team:'inter' },{ id:'m1', name:'Leao', team:'milan'}];
  getOptions$() {
    return of(this._options).pipe(
      map(options => options.map( o=> ({value: o.id, label: o.name, source: o})))
    )
  }
}
