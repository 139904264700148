import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const DATA_SOURCE = new InjectionToken<IDataSource>('DATA_SOURCE');

export interface IDataSource {
  name: string;
  getOptions$(): Observable<{value: string, label: string, source: any,}[]>
}

