import { Injectable } from "@angular/core";
import { IID_OL_RISORSA } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRisorsaStore } from "./iidOlRisorsa.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRisorsaQuery extends AbstractInterfaceDataQuery<IID_OL_RISORSA> {
  constructor(protected store: IIDolRisorsaStore) {
    super(store);
  }
}
