import { IProject } from '@common/interfaces/project';
import { EntityState, EntityStore, StoreConfig, EntityUIStore, ActiveState, Constructor } from '@datorama/akita';
import { Inject, Injectable } from '@angular/core';
import { ID as string } from '@common/interfaces/id';
import { Topics } from '@common/interfaces/topics';
import { STORAGE_INFO_KEY, StorageInfo } from '@ep-om/app.module';
import { LocalStorageService } from '@ep-om/project/service/localstorage.service';


export type JProjectUI = {
  id: string;
  newsBadge: string[];
  item: string;
  itemView: { [item: string]: string }
}

export interface ProjectState extends EntityState<IProject, string>, ActiveState<string> { };
export interface ProjectUIState extends EntityState<JProjectUI, string>, ActiveState<string> { };

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Topics.PROJECTS, resettable: true })
export class ProjectStore extends EntityStore<ProjectState> {

  ui: EntityUIStore<ProjectUIState>;
  private storageInfoInitialized = false;


  constructor(
    private storageService: LocalStorageService,
    @Inject(STORAGE_INFO_KEY) private storageInfoKey: string,
  ) {
    super();
    this.createUIStore().setInitialEntityState({ newsBadge: [] });
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        currentInfo = currentInfo || [];
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: false })
        }
        return currentInfo;
      }
    )
  }

  upsertMany(entities: IProject[], options?: { baseClass?: Constructor; loading?: boolean; }): void {
    super.upsertMany(entities, options);
    if (this.storageInfoInitialized) {
      return;
    }
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: true })
        } else {
          currentInfo[storeIdx].hasValue = true;
        }
        return currentInfo;
      }
    )
    this.storageInfoInitialized = true;
  }

  reset(): void {
    super.reset();
    this.storageInfoInitialized = false;
  }
}
