import { enableProdMode } from '@angular/core';
import '@angular/compiler';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { akitaConfig } from '@datorama/akita';
import { localforageProviders } from './localforage.setings';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

akitaConfig({ resettable: true });

/* const initSentry = () => {
  Sentry.init({
    dsn: 'https://b2af8332e38f486d910f06b79df66365@o495789.ingest.sentry.io/5569161',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://jira.trungk18.com/'],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    tracesSampleRate: 1.0
  });
}; */

if (environment.production) {
  enableProdMode();
  //initSentry();
}

platformBrowserDynamic(localforageProviders)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
