import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { IssueTypeState, IssueTypeStore } from "./issueType.store";

@Injectable({
  providedIn: 'root'
})
export class IssueTypeQuery extends QueryEntity<IssueTypeState> {
  all$ = this.selectAll();

  constructor(protected store: IssueTypeStore) {
    super(store);
  }
}
