import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { IssueStateWorkflowState, IssueStateWorkflowStore } from "./issueStateWorkflow.store";
import { Observable } from 'rxjs';
import { IIssueStateWorkflow } from "@common/interfaces/issueStateWorkflow";

@Injectable({
  providedIn: 'root'
})
export class IssueStateWorkflowQuery extends QueryEntity<IssueStateWorkflowState> {
  all$ = this.selectAll();

  constructor(protected store: IssueStateWorkflowStore) {
    super(store);
  }

  public selectByWorkflow$(id:string): Observable<IIssueStateWorkflow[]> {
    //logger.log(id)
    return this.selectAll({
      filterBy: ({ workflowId }) => workflowId === id
    })
  }

  public getByWorkflow(id: string): IIssueStateWorkflow[] {
    return this.getAll({
      filterBy: ({ workflowId }) => workflowId === id
    })
  }

  // public async selectByWorkflow(id:string): Promise<IIssueStateWorkflow[]> {
  //   //logger.log(id)
  //   return await this.select({
  //     filterBy: ({ workflowId }) => workflowId === id
  //   }).toPromise()
  // }

}
