import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_OL_APPARATO } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDolApparatoState extends EntityState<IID_OL_APPARATO, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_APPARATO })
export class IIDolApparatoStore extends EntityStore<IIDolApparatoState> {
  constructor() {
    super([]);
  }
}
