import { Injectable } from "@angular/core";
import { IID_OL_APPARATO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolApparatoStore } from "./iidOlApparato.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolApparatoQuery extends AbstractInterfaceDataQuery<IID_OL_APPARATO> {

  constructor(protected store: IIDolApparatoStore) {
    super(store);
  }
}
