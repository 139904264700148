import { Injectable } from "@angular/core";
import { IID_OL_ACTIVATION_REQUEST } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolActivationRequestStore } from "./iidOlActivationRequest.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolActivationRequestQuery extends AbstractInterfaceDataQuery<IID_OL_ACTIVATION_REQUEST> {
  constructor(protected store: IIDolActivationRequestStore) {
    super(store);
  }
}
