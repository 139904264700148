import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LastUpdate } from './lastUpdate.model';

export interface LastUpdateState extends LastUpdate {};

function createInitialState(): LastUpdateState {
  return {
    Users: null,
    Projects: null,
    Workflows: null,
    IssueStates: null,
    IssueStateWorkflows: null,
    IssueTypes: null,
    IssueTypeInterfaces: null,
    IssueTypeWorkflows: null,
   }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'lastUpdate'
})
export class LastUpdateStore extends Store<LastUpdateState> {
  constructor() {
    super(createInitialState());
  }
}
