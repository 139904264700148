import { Injectable } from "@angular/core";
import { IID_GR_RESOURCES } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDGrResourcesStore } from "./iidGRResources.store";

@Injectable({
  providedIn: 'root'
})
export class IIDGrResourcesQuery extends AbstractInterfaceDataQuery<IID_GR_RESOURCES> {

  constructor(protected store: IIDGrResourcesStore) {
    super(store);
  }

}
