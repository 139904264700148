import { Injectable } from "@angular/core";
import { IID_OLO_DEACTIVATION_VALIDATION } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolDeactivationValidationStore } from "./iidOlDeactivationValidation.store";


@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationValidationQuery extends AbstractInterfaceDataQuery<IID_OLO_DEACTIVATION_VALIDATION> {

  constructor(protected store: IIDolDeactivationValidationStore) {
    super(store);
  }
}
