import { Injectable } from "@angular/core";
import { ITag } from "@common/interfaces/tag";
import { ID, QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TagState, TagStore } from "./tag.store";

@Injectable({
  providedIn: 'root'
})
export class TagQuery extends QueryEntity<TagState> {
  constructor(protected store: TagStore) {
    super(store);
  }

  getByTagIds(ids: ID[]) {
    return this.getAll({
      filterBy: tag => ids.includes(tag.id)
    })
  }

  getTagsByProjectId(projectId: ID): ITag[] {
    return this.getAll({
      filterBy: entity => entity.projectId === projectId
    })
  }

  selectTagsByProjectId(projectId: ID): Observable<ITag[]> {
    return this.selectAll({
      filterBy: entity => entity.projectId === projectId
    })
  }

  selectTagsByProjectIdNotTrashed(projectId: ID): Observable<ITag[]> {
    return this.selectAll({
      filterBy: tag => tag.projectId === projectId && !tag.trashedAt
    })
  }

  findTagByLabel(label: string): ITag {
    const tag = this.getAll({
      filterBy: entity => entity.label === label
    });
    if (tag) {
      return tag[0];
    }
    return null;
  }
  selectTagByLabel(label: string): Observable<ITag> {
    return this.selectAll({
      filterBy: tag => tag.label === label
    }).pipe(map(tags => tags[0]))
  }
  selectTagsByTagsId(tagsId: string[]): Observable<ITag[]> {
    return this.selectMany(tagsId);
  }
}
