import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getSelectOptionsFromEnum } from '@ep-om/utils/selectUtil';

@Component({
  selector: 'j-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {

  @Input() control: FormControl;
  @Input() disabled: boolean = false;
  @Input() object: Object;
  @Input() label;
  @Input() hidden: boolean = false;
  @Input() width: string;
  @Input() ordered: boolean = false;
  @Input() orderNumericString: boolean = false;
  selectOptions: any;

  constructor() { }

  get _width() {
    if (this.width) {
      return { width: this.width }
    }
  }

  private setSelectOptions() {
    this.selectOptions = getSelectOptionsFromEnum(this.object, this.ordered, this.orderNumericString);
  }

  ngOnInit(): void {
    this.setSelectOptions();
    this.control = this.control ?? new FormControl('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSelectOptions();
  }
}
