import { Injectable } from "@angular/core";
import { IIssueState } from "@common/interfaces/issueState";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IssueStateState extends EntityState<IIssueState, ID> { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_STATES })
export class IssueStateStore extends EntityStore<IssueStateState> {
    constructor() {
        super([]);
    }
}
