import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { RoleNames } from '@common/interfaces/permissions';
import { IUser } from '@common/interfaces/user';
import { Store, StoreConfig } from '@datorama/akita';
import { v4 } from 'uuid';
import { CLIENT_ID_LOCALSTORAGE_KEY } from '@ep-om/app.service';

export interface AuthState {
  clientId: string;
  accessToken: string;
  refreshToken: string;
  userId: ID;
  userAgent: string;
  projectRole: { [key: string]: RoleNames[] },
  user: IUser,
}

export function clientId():string {
  let rv = window.localStorage.getItem(CLIENT_ID_LOCALSTORAGE_KEY);
  if(!rv) {
    rv = v4();
    window.localStorage.setItem(CLIENT_ID_LOCALSTORAGE_KEY,rv);
  } 
  return rv;
}

export function getResettedProperties(state: AuthState) {
  return {
    ...state,
    refreshToken: null,
    accessToken: null,
    projectRole: null,
    userId: null,
    user: null,
  }
}

export function createInitialAuthState(): AuthState {
  // return {
  //   clientId: v4(), userAgent: window.navigator.userAgent
  // } as AuthState;
  let authState = JSON.parse(localStorage.getItem('authStore'))?.auth as AuthState;
  if (!authState) {
    authState = {
      clientId: clientId(), userAgent: window.navigator.userAgent
    } as AuthState;
  }
  if (authState && (authState.userAgent !== window.navigator.userAgent || authState.clientId!==clientId())) {
    authState = { clientId: clientId(), userAgent: window.navigator.userAgent } as AuthState;
  }
  return authState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialAuthState());
    if (window.navigator.userAgent !== this.getValue().userAgent) {
      this.update(state => ({... state, userAgent: window.navigator.userAgent}));
    }
  }



  reset(): void {
    this.update(state => getResettedProperties(state));
  }
}
