import { Injectable } from '@angular/core';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { TagStore } from './tag.store';
import { TagQuery } from './tag.query';
import { ITag } from '@common/interfaces/tag';
import ColorHash from 'color-hash';
import { ID } from '@common/interfaces/id';
import { PartialUpdate } from '@common/interfaces/base';
import { WsACKCallback } from '@common/api/ack';
@Injectable({
  providedIn: 'root'
})
export class TagService extends CrudService<ITag, TagStore, TagQuery> {

  colorHash = new ColorHash();

  constructor(
    protected store: TagStore,
    public query: TagQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy,
  ) {
    super(
      Topics.TAGS,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  persistTags(tags: ITag[], projectId: ID) {
    tags = tags.map(tag => ({
      ...tag,
      id: tag.id.replace('new_', ''),
      projectId
    }));
    for (const tag of tags) {
      if (!this.query.getEntity(tag.id)) {
        super.create(tag);
      }
    }
  }

  populateStore(tags: ITag[]) {
    for (const tag of tags) {
      if (!this.query.getEntity(tag.id)) {
        this.store.add(tag);
      }
    }
  }

  trash(id: ID) {
    this.update({ id, trashedAt: (new Date()).toISOString() } as PartialUpdate<ITag>);
  }

  restoreFromTrash(id: ID) {
    this.update({ id, trashedAt: null } as PartialUpdate<ITag>);
  }

  getTagsForProject(projectId: string, callback?: WsACKCallback<ITag[]>) {
    this.actionService.sendMessage(Topics.TAGS, 'tags_by_project', { projectId }, callback);
  }
}
