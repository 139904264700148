import { Injectable } from '@angular/core';
import { IIDolActivationRequestStore } from './iidOlActivationRequest.store';
import { IID_OL_ACTIVATION_REQUEST } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolActivationRequestQuery } from './iidOlActivationRequest.query'
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolActivationRequestService extends CrudService<IID_OL_ACTIVATION_REQUEST, IIDolActivationRequestStore, IIDolActivationRequestQuery> {
  constructor(
    protected store: IIDolActivationRequestStore,
    public query: IIDolActivationRequestQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy,
  ) {
    super(
      Topics.IID_OL_ACTIVATION_REQUEST,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
