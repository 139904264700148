import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { ActionState, ActionStore } from './action.store';
import { hasId } from './action.service';

@Injectable({
  providedIn: 'root'
})
export class ActionQuery extends Query<ActionState> {
  inTransfer$ = this.select('inTransfer');
  queue$ = this.select('queue');
  buffer$ = this.select('buffer');
  
  constructor(protected store: ActionStore) {
    super(store);
  }

  /**
   * this method is used to check if there is an action in queue to update a property of an item
   * is typically used by the abstract interface data component to check if the form can be patched with the values coming from the backend, without loosing the current user input.
   * at this stage, the last who change the data wins.
   * @param itemId 
   * @param key 
   * @returns 
   */
  isItemAndKeyChanging(itemId: string, key: string): boolean {
    const actions = this.getValue();
    return actions.buffer.some(action => hasId(action.payload) && action.payload.id === itemId && key in action.payload)
      || actions.queue.some(action => hasId(action.payload) && action.payload.id === itemId && key in action.payload)
      || actions.inTransfer && hasId(actions.inTransfer.payload) && actions.inTransfer.payload.id === itemId  && key in actions.inTransfer.payload
  }
}
