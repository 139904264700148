import { Injectable } from "@angular/core";
import { IID_OL_ACCETTATO_KO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolAccettatoKoStore } from "./accettatoKo.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAccettatoKoQuery extends AbstractInterfaceDataQuery<IID_OL_ACCETTATO_KO> {

  constructor(protected store: IIDolAccettatoKoStore) {
    super(store);
  }
}
