import { Injectable } from "@angular/core";
import { IID_OL_ACCETTATO_KO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolAccettatoKoQuery } from "./accettatoKo.query";
import { IIDolAccettatoKoStore } from "./accettatoKo.store"; 

@Injectable({
  providedIn: 'root'
})
export class IIDolAccettatoKoService extends CrudService<IID_OL_ACCETTATO_KO, IIDolAccettatoKoStore, IIDolAccettatoKoQuery> {
  constructor(
    protected store: IIDolAccettatoKoStore,
    public query: IIDolAccettatoKoQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ACCETTATO_KO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
