import { Injectable } from "@angular/core";
import { IID_OL_SOSPENSIONE } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolSospensioneStore } from "./iidOlSospensione.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolSospensioneQuery extends AbstractInterfaceDataQuery<IID_OL_SOSPENSIONE> {
  constructor(protected store: IIDolSospensioneStore) {
    super(store);
  }
}
