import { Injectable } from "@angular/core";
import { IID_OL_APPUNTAMENTO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolAppuntamentoStore } from "./iidOlAppuntamento.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAppuntamentoQuery extends AbstractInterfaceDataQuery<IID_OL_APPUNTAMENTO> {

  constructor(protected store: IIDolAppuntamentoStore) {
    super(store);
  }

  getOneByIssueId(issueId: string) {
    return this.getAll({
      filterBy: appuntamento => appuntamento.issueId === issueId
    })?.[0]
  }
}
