import { Injectable, InjectionToken } from "@angular/core";
import { EntityResponse, WsACKResponse } from "@common/api/ack";
import { IAppointment } from "@common/interfaces/appointment";
import { IsPaginated } from "@common/interfaces/base";
import { IIssue } from "@common/interfaces/issue";
import { AppointmentService } from "../../appointment/appointment.service";
import { IssueService } from "../../issue/issue.service";
import { TagService } from "../../tag/tag.service";
import { Action } from "../action.model";

export interface ResponseHandler {
  success?<T>(action: Action, response: WsACKResponse<T>): Promise<void> | void
  error?<T>(action: Action, response: WsACKResponse<T>): Promise<void> | void
}

@Injectable({
  providedIn: 'any'
})
export class IssueCreationHandler implements ResponseHandler {
  constructor(
    private tagService: TagService,
    private issueService: IssueService,
  ) {}
  success(action: Action, response: WsACKResponse<any>) {
    const issue = action.payload as IIssue;
    const tagsToRemove = issue.tagIds.filter(id => id.startsWith('new'));
    this.tagService.localRemoveEntities(tagsToRemove);
  }
}

@Injectable({
  providedIn: 'any'
})
export class AppointmentRemoteGetByUsersHandler implements ResponseHandler {
  constructor(
    private appointmentService: AppointmentService
  ) {}

  success(action: Action, response: WsACKResponse<any>) {
    if (IsPaginated<EntityResponse<IAppointment[]>>(response.body)) {
      console.log('remote_get , remaining', response.body.remaining);
      if (response.body.remaining > 0) {
        const lastItem = response.body.data.entities.at(-1);
        this.appointmentService.remoteGet({ entity: 'Appointment', ...action.payload, lastId: lastItem.id, updatedAt: lastItem.updatedAt }, 'AppointmentRemoteGet')
      } else {
        console.log('remote_get appointments done!')
      }
    }
  }
}


export const ISSUE_CREATION = new InjectionToken<ResponseHandler>('IssueCreation');
export const APPOINTMENT_REMOTE_GET = new InjectionToken<ResponseHandler>('AppointmentRemoteGet');
//export const SEND_CHUNK = new InjectionToken<ResponseHandler>('SendNextChunk');
export const handler_dictionary = {
  'IssueCreation': ISSUE_CREATION,
  'AppointmentRemoteGet': APPOINTMENT_REMOTE_GET
} as const;

export type HandlerName = keyof typeof handler_dictionary;
