import { Injectable } from "@angular/core";
import { ID } from '@common/interfaces/id';
import { IID_GR_TASKS } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDGrTasksState extends EntityState<IID_GR_TASKS, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_GR_TASKS })
export class IIDGrTasksStore extends EntityStore<IIDGrTasksState> {

  constructor() {
    super([]);
  }

}
