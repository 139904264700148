import { Injectable } from "@angular/core";
import { IBaseInterfaceData } from "@common/interfaces/base";
import { Topics } from "@common/interfaces/topics";
import { EntityStore, EntityState, QueryEntity } from "@datorama/akita";
import { ActionService } from "./action/action.service";
import { CrudService } from "./crudService";
import { AbstractInterfaceDataQuery } from "./interfaceDataModel";
import { ProjectEntityStoreStrategy } from "./updateStoreStrategies";

@Injectable({
  providedIn: 'any'
})
export class CrudServiceBuilder {
  constructor(
    private actionService: ActionService,
    private projectStoreStrategy: ProjectEntityStoreStrategy,
  ) {}
  
  public buildService<
    T extends IBaseInterfaceData,
    S extends EntityStore<EntityState<T, any>, T, any>,
    Q extends QueryEntity<EntityState<T, any>, T, any>
  >(store: S, query: Q): (new () => CrudService<T, S, Q>) {
    const actionService = this.actionService;
    const strategy = this.projectStoreStrategy;
    return class extends CrudService<T, S, Q> {
      constructor() {
        super(Topics.META_INTERFACES_DATA, store, query, actionService, strategy);
      }
    }
  }

  public buildQuery<T extends IBaseInterfaceData, S extends EntityStore<EntityState<T, any>, T, any>>() {
    return class extends AbstractInterfaceDataQuery<T> {
      constructor(protected store: S) {
        super(store);
      }
    }
  }
}
