
import { Injectable } from "@angular/core";
import { IID_MAIL_GTW_SOURCE } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDMailGtwStore } from "./iidMailGtw.store";

@Injectable({
  providedIn: 'root'
})
export class IIDMailGtwQuery extends AbstractInterfaceDataQuery<IID_MAIL_GTW_SOURCE> {
  constructor(protected store: IIDMailGtwStore) {
    super(store);
  }
}
