import { Component, OnInit } from '@angular/core';
import { getInterfaceNameKeyFromValue } from '@common/interfaces/issueTypeInterface';
import { IUser } from '@common/interfaces/user';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-interface-visibility',
  templateUrl: './formly-interface-visibility.component.html',
  styleUrls: ['./formly-interface-visibility.component.scss']
})
export class FormlyInterfaceVisibilityComponent extends FieldType implements OnInit {
  interfaceName: string;

  ngOnInit(): void {
    if(!this.model || !this.model['interfaceName']) {
      this.formControl.reset()
      return;
    }
    const interfaceName = this.model['interfaceName'];
    if(interfaceName) {
      this.interfaceName = getInterfaceNameKeyFromValue(interfaceName);
    }
  }
 
  onClick(event: IUser) {
    this.formControl.setValue(event.id);
    this.formControl.root.markAsDirty();
  }
}
