import { Inject, Injectable, Injector } from "@angular/core";
import { createEntityQuery, createEntityStore, EntityState, EntityStore, persistState, QueryEntity } from "@datorama/akita";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "@services/localstorage.service";
import * as localforage from "localforage";
import { STORAGE_INFO_KEY, StorageInfo } from "src/app/app.module";
import { CrudService } from "../crudService";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { IBaseEntity, IBaseInterfaceData } from "@common/interfaces/base";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { CrudServiceBuilder } from "../crudServiceBuilder";

@Injectable({
  providedIn: 'root'
})
export class MetaInterfaceServiceProvider {
  private _services: { [storename: string]: CrudService<any, any, any> } = {}
  private _storeNames$ = new BehaviorSubject<string[]>([]);
  public storeNames$ = this._storeNames$.asObservable();

  constructor(
    private localStorage: LocalStorageService,
    @Inject(STORAGE_INFO_KEY) private storageInfoKey: string,
    private crudServiceBuilder: CrudServiceBuilder,
  ) {}

  loadStores(_stores: {name: string, idKey?: string}[]) {
    const stores = _stores.filter(store => !this._services[store.name]);
    for (const store of stores) {
      const entityStore = createEntityStore<EntityState<any, any>>([], {
        name: store.name,
        idKey: store.idKey || 'id',
      });
      persistState({
        key: store.name,
        storage: localforage,
        include: [store.name]
      });
      const query = new (this.crudServiceBuilder.buildQuery())(entityStore);
      const service = new (this.crudServiceBuilder.buildService(entityStore, query))();
      this._services[store.name] = service;
    }
    const storeNames = Object.keys(this._services);
    this._storeNames$.next(storeNames);
  }

  addStore(store: {name: string, idKey?: string}) {
    console.log('adding store', store.name);
    this.loadStores([store]);
    this.localStorage.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        if (!currentInfo.some(currentStore => currentStore.name === store.name)) {
          currentInfo.push({
            name: store.name,
            isMeta: true,
            idKey: store.idKey,
            hasValue: false,
          });
        }
        return currentInfo;
      }
    )
  }
  
  removeStore(storeNameToBeRemoved: string) {
    const storeNames = Object.keys(this._services);
    const remainingStoreNames = storeNames.filter(name => name !== storeNameToBeRemoved);
    this.localStorage.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        return currentInfo?.filter(store => store.name !== storeNameToBeRemoved);
      }
    );
    this._storeNames$.next(remainingStoreNames);
  }

  /**
   * 
   * @param name is the name of the store - aka the interface value
   * @returns CrudService
   */
  getService(name: string) {
    return this._services[name];
  }

  /**
   * 
   * @param name is the name of the store - aka the interface value
   * @returns AbstractInterfaceDataQuery
   */
  getQuery(name: string) {
    return this._services[name]?.query;
  }
}
