import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { ITag } from "@common/interfaces/tag";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface TagState extends EntityState<ITag, string> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.TAGS })
export class TagStore extends EntityStore<TagState> {
  constructor() {
    super([]);
  }
}
