import { Component } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FieldType, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div class="container p-2 mb-2">
      <div class="toolbar mb-2">
          <b><i nz-icon nzType="control" nzTheme="outline" class="align-middle text-lg"></i> {{ props.label }}</b>
          <button *ngIf="canAdd()" nz-button nzType="primary" (click)="add()" nzShape="circle" nzSize="small"><i nz-icon nzType="plus" nzTheme="outline"></i></button>
      </div>
      <div *ngFor="let field of field.fieldGroup; let i = index" class="fg">
          <formly-field [field]="field" class="container"></formly-field>
          <div [ngClass]="{'buttons-vertical': !!field.fieldGroup,'buttons-vertical-top': !field.fieldGroup }">
            <button *ngIf="canRemove()" nz-button nzType="primary" (click)="remove(i)" nzShape="circle" nzDanger><i nz-icon nzType="delete"></i></button>
            <button *ngIf="canMove()" nz-button nzType="default" nzShape="circle" (click)="movePrev(field)" [disabled]="!canMovePrev(field)"><i nz-icon nzType="arrow-up"></i></button>
            <button *ngIf="canMove()" nz-button nzType="default" nzShape="circle" (click)="moveNext(field)" [disabled]="!canMoveNext(field)"><i nz-icon nzType="arrow-down"></i></button>
          </div>
      </div>
    </div>
  `,
  styles:[
    `.container { width: 100%; }`
  ]
})
export class RepeatSectionTypeComponent extends FieldArrayType {

  override onPopulate(field: FieldArrayTypeConfig<(FormlyFieldProps & { [additionalProperties: string]: any; }) | undefined>) {
    super.onPopulate(field);
  }

  canAdd():boolean { 
    return !(this.props.canAdd == false);
  }
  canRemove():boolean { 
    return !(this.props.canRemove == false);
  }
  canMove():boolean { 
    return !(this.props.canMove == false); 
  }

  movePrev(item:any) {
    const idx = this.field.fieldGroup.indexOf(item);
    this.move(-1,idx);
  }
  moveNext(item:any) {
    const idx = this.field.fieldGroup.indexOf(item);
    this.move(1,idx);
  }

  move(shift:number, currentIndex:number) {
    const arrayOfValues = this.field.formControl.value;
    const newIndex = currentIndex + shift;
    const removed = arrayOfValues.splice(currentIndex,1)[0];
    arrayOfValues.splice(newIndex, 0, removed);
    this.field.formControl.patchValue(arrayOfValues);
    this.field.formControl.markAsDirty();
  }

  canMovePrev(item:any):boolean { 
    const first = this.field.fieldGroup[0];
    const isFirst = item.key===first.key;
    return !isFirst;
  }

  canMoveNext(item:any):boolean { 
    const count = this.field.fieldGroup.length;
    const last = this.field.fieldGroup[count-1];
    const isLast = item.key===last.key;
    return !isLast;
  }

}
