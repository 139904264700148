import { Injectable } from '@angular/core';
import { AppointmentAdditionalInfo, IAppointment } from '@common/interfaces/appointment';
import { Topics } from '@common/interfaces/topics';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { isSameDay, formatISO } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { BaseEntityStoreStrategy } from '../updateStoreStrategies';
import { AppointmentQuery } from './appointment.query';
import { AppointmenteStore } from './appointment.store';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends CrudService<IAppointment, AppointmenteStore, AppointmentQuery> {

  constructor(
    protected store: AppointmenteStore,
    public query: AppointmentQuery,
    protected actionService: ActionService,
    protected updateStrategy: BaseEntityStoreStrategy,
    private socketService: SocketIoService,
  ) { 
    super(
      Topics.APPOINTMENT,
      store,
      query,
      actionService,
      updateStrategy
    )
  }

  localRemoveByUserId(userIds: string[]) {
    this.store.remove(({ userId }) => userIds.includes(userId));
  }

  updateByDate(date$: Observable<Date>, destroyer$: Subject<void>) {
    return this.socketService.uponConnection$.pipe(
      switchMap(
        () => date$.pipe(
          distinctUntilChanged((prev, curr) => {
            return isSameDay(prev, curr)
          }),
          switchMap(date => this.socketService.listenResource<AppointmentAdditionalInfo[]>(Topics.APPOINTMENT, 'entity_by_date', {date: formatISO(date)})),
          map(updates => updates.data),
      ),),
      takeUntil(destroyer$),
      this.socketService.stopOnDisconnectionPipe(),
      this.socketService.repeatOnConnectionPipe(),
      tap(infos => {
        console.log('infos', infos);
        infos.forEach(info => this.store.update(info.id, entity => ({...entity, additionalInfo: info})));
      })
    )
  }

}
