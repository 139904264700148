import { Injectable } from "@angular/core";
import { IID_OL_RISORSA_PNI } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRisorsaPniStore } from "./iidOlRisorsaPni.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRisorsaPniQuery extends AbstractInterfaceDataQuery<IID_OL_RISORSA_PNI> {
  constructor(protected store: IIDolRisorsaPniStore) {
    super(store);
  }
}
