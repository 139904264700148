import { of } from "rxjs";
import { IDataSource } from "./datasource.abstract";
import { map } from "rxjs/operators";

export class TeamsDataSource implements IDataSource {
  public readonly name = 'TEAMS';
  private _options = [{ id:'juve', name:'Juventus' },{ id:'milan', name:'AC Milan' },{ id:'inter', name:'FC Internazionale' },{ id:'roma', name:'AC Roma' },];
  getOptions$() {
    return of(this._options).pipe(
      map(options => options.map( o => ({value: o.id, label: o.name, source: o})))
    )
  }
}
