import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_KO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolEspletatoKoStore } from "./espletatoKo.store";



@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoKoQuery extends AbstractInterfaceDataQuery<IID_OL_ESPLETATO_KO> {

  constructor(protected store: IIDolEspletatoKoStore) {
    super(store);
  }
}
