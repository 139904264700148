import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '@common/interfaces/user';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-user-select',
  templateUrl: './formly-user-select.component.html',
  styleUrls: ['./formly-user-select.component.scss']
})
export class FormlyUserSelectComponent extends FieldType implements OnInit {
  selectedUserId: string;

  ngOnInit(): void {
    if(!this.model || !this.model[this.field.key as string]) {
      this.formControl.reset()
      return;
    }
    const id = this.model[this.field.key as string];
    if(id) {
      this.selectedUserId = id;
    }
  }
 
  onClick(event: IUser) {
    this.formControl.setValue(event.id);
    this.formControl.root.markAsDirty();
  }
}
