import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_RIFERIMENTI } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRiferimentiState, IIDolRiferimentiStore } from "./iidOlRiferimenti.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRiferimentiQuery extends AbstractInterfaceDataQuery<IID_OL_RIFERIMENTI> {
  constructor(protected store: IIDolRiferimentiStore) {
    super(store);
  }
}
