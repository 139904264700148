import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiStore, UiState } from './ui.store';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UiQuery extends Query<UiState> {
  interfaceNotNull$ = this.select('interface').pipe(filter(interfaceSettings => !!interfaceSettings));
  expandedNavbar$ = this.select('expandedNavbar');
  currentProjectId$ = this.select('prjId');
  isSmallScreen$ = this.select('isSmallScreen');
  
  constructor(protected store: UiStore) {
    super(store);
  }

  getLastPosition() {
    return this.getValue().lastPosition;
  }

  getIsSmallScreen() {
    return this.getValue().isSmallScreen;
  }

}
