import { Injectable } from '@angular/core';
import { ID } from '@common/interfaces/id';
import { InterfaceNameValue } from '@common/interfaces/issueTypeInterface';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface IFilter {
  projectId: string;
  searchTerm: string;
  assignee: string[];
  reporter: string[];
  onlyMyIssue: boolean;
  nearDueDate: boolean;
  onlyNews: boolean;
  tag: string[];
  milestone: string[];
  issueState: string[];
  issueType: string[];
  priority: string[];
  settings: {
    search: {interfaceName: InterfaceNameValue, typeInterfaceName: string}[];
  };
}

export interface FilterState extends EntityState<IFilter, string> { }

export function createInitialFilterState(projectId: string): IFilter {
  return {
    projectId,
    searchTerm: '',
    assignee: [],
    reporter: [],
    onlyMyIssue: false,
    nearDueDate: false,
    onlyNews: false,
    tag: [],
    milestone: [],
    issueState: [],
    issueType: [],
    priority: [],
    settings: {
      search: []
    },
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'filter', idKey: 'projectId', resettable: true })
export class FilterStore extends EntityStore<FilterState> {
  constructor() {
    super();
  }
}
