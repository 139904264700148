import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LastUpdateState, LastUpdateStore } from './lastUpdate.store';

@Injectable({ providedIn: 'root' })
export class LastUpdateQuery extends Query<LastUpdateState> {
  constructor(protected store: LastUpdateStore) {
    super(store);
  }
}
