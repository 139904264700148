import { Injectable, Injector } from "@angular/core";
import { EntityResponse, WsACKCallback, WsACKResponse } from '@common/api/ack';
import { IsPaginated } from "@common/interfaces/base";
import { IssueTopics, Topics } from "@common/interfaces/topics";
import { EntityStoreAction, runEntityStoreAction } from "@datorama/akita";
import { SocketIoService } from "@ep-om/core/services/socket-io.service";
import clone from "clone";
import { compareAsc } from "date-fns";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { sha1 } from "object-hash";
import { Subject, merge, of } from 'rxjs';
import { debounceTime, delayWhen, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { IssueStore, PendingState } from "../issue/issue.store";
import { Action } from "./action.model";
import { ActionQuery } from "./action.query";
import { ActionStore } from "./action.store";
import { ResponseHandler, handler_dictionary } from "./handlers/handlers";

const DEBOUNCE_TIME_MS = 500;

export function hasId(payload: any): payload is ({ id: string }) {
  return Object.prototype.hasOwnProperty.call(payload, 'id');
}

function calculateKey(action: Action) {
  return hasId(action.payload) ? `${action.topic}#${action.entity}#${action.type}#${action.payload.id}` : crypto.randomUUID();
}

function packActions(actions: Action[]): Action[] {
  let action = clone(actions[0]);
  if (actions.length === 1) {
    return [action];
  }
  const queue: Action[] = [action];
  let actionCount = 0;
  let prevKey = calculateKey(action);
  for (let i = 1; i < actions.length; i++) {
    let currAction = clone(actions[i]);
    let currKey = calculateKey(currAction);
    if (prevKey === currKey) {
      queue[actionCount] = {
        ...queue[actionCount] || {},
        ...currAction,
        payload: {
          ...queue[actionCount].payload,
          ...currAction.payload,
        }
      }
    } else {
      queue[++actionCount] = currAction;
    }
    prevKey = currKey;
  }
  return queue;
}

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private callbacks: { [key: string]: WsACKCallback } = {};
  newAction$ = new Subject<Action>();
  constructor(
    public store: ActionStore,
    public query: ActionQuery,
    private socketService: SocketIoService,
    private _nzNotificationService: NzNotificationService,
    private issueStore: IssueStore,
    private injector: Injector,
  ) {
    this.query.buffer$.pipe(
      debounceTime(DEBOUNCE_TIME_MS),
      filter(buffer => !!buffer && buffer.length > 0),
    ).subscribe(actions => {
      console.log(`[ACTION_SERVICE] packing ${actions.length} actions`);
      const queue = packActions(actions);
      console.log(`[ACTION_SERVICE] adding ${queue.length} actions in queue`);
      this.store.update((state => ({
        ...state,
        buffer: state.buffer.filter(action => !actions.some(a => a.id === action.id)),
        queue: [...state.queue, ...queue],
      })));
      console.log(`[ACTION_SERVICE] new queue contains ${this.query.getValue().queue.length} actions`);
    });

    //put action in transfer
    merge(
      this.query.inTransfer$.pipe(filter(action => !action)),
      //connected$
      this.query.queue$
    ).subscribe(() => {
      const currentStore = this.store.getValue();
      if (!currentStore.inTransfer && currentStore.queue.length === 0) {
        Object.values(this.issueStore.ui.getValue().entities).forEach(issueUi => {
          if (issueUi.pendingState === 'pending') {
            this.issueStore.ui.update(issueUi.id, { pendingState: 'done' });
          }
        });
        return;
      }
      if (currentStore.inTransfer || currentStore.queue.length === 0) {
        return;
      }
      this.store.update(state => {
        const actionsToBeMade = state.queue.filter(action => !action.notBeforeThan || compareAsc(new Date(), new Date(action.notBeforeThan)) >= 0);

        if (actionsToBeMade.length == 0) {
          return state;
        }

        const inTransfer = clone(actionsToBeMade[0]);
        console.log(`[ACTION_SERVICE] new action in transfer: ${inTransfer.id}`);
        const newQueue = state.queue.filter(action => action.id !== inTransfer.id);
        return { queue: newQueue, inTransfer };
      })
    });

    //process the inTransfer action
    merge(
      this.socketService.uponConnection$,
      this.query.inTransfer$.pipe(
        filter(action => !!action),
        distinctUntilChanged((x, y) => sha1(x) === sha1(y)),
      ),
    ).pipe(
      switchMap((x) => of(x).pipe(delayWhen(() => this.socketService.uponConnection$)))
    ).subscribe((value) => {
      const action = this.query.getValue().inTransfer;
      if (!action) {
        return;
      }
      console.log("[ACTION_SERVICE] emitting action ", action);
      this.socketService.sockets[action.topic].emit(
        `${action.type}`,
        action.topic === action.entity
          ? action.payload
          : {
            entity: action.entity,
            data: action.payload
          },
        response => this.onSuccess(response, action.id)
        /* this.withTimeout(
          this.onSuccess,
          this.onTimeout,
          50000
        ));*/)
    });
  }

  setPendingState(incomingAction: Omit<Action, 'id'>, pendingState: PendingState) {
    if ((Object.values(IssueTopics).includes(incomingAction.topic as any) && incomingAction.type === 'update')) {
      this.issueStore.ui.update(incomingAction.backup['issueId'] || incomingAction.payload['id'], { pendingState });
      // runStoreAction('UI/Issues', StoreAction.Update, update => update({id: incomingAction.backup['issueId'] || incomingAction.payload['id'], pendingState }));
    }
    return;
  }

  queueAction(incomingAction: Omit<Action, 'id'>) {
    if (hasId(incomingAction.payload) && incomingAction.payload.id.startsWith('new_')) {
      return;
    }
    this.setPendingState(incomingAction, 'pending');
    let id: string;
    if (hasId(incomingAction.payload)) {
      id = `${incomingAction.topic}_${incomingAction.entity}_${incomingAction.type}_${incomingAction.payload.id}_${Date.now()}`
    } else {
      id = `${incomingAction.topic}_${incomingAction.entity}_${incomingAction.type}_${Date.now()}`
    }

    const action: Action = {
      id,
      entity: incomingAction.entity,
      topic: incomingAction.topic,
      type: incomingAction.type,
      payload: incomingAction.payload,
      backup: incomingAction.backup,
      ...(incomingAction.responseHandlerName && { responseHandlerName: incomingAction.responseHandlerName }),
      ...(incomingAction.notBeforeThan && { notBeforeThan: incomingAction.notBeforeThan }),
    };

    console.log(`[ACTION_SERVICE] would like to modify ${action.topic} with`, JSON.stringify(action.payload));

    this.store.update(state => ({
      ...state,
      buffer: [...state.buffer, action],
    }));
  }

  onSuccess(response: WsACKResponse<any>, actionId: string) {
    //const callback = this.callbacks[actionId];
    const inTransfer = this.store.getValue().inTransfer;
    // if(debugMode) {logger.log('WSonSuccess', response, inTransfer);}
    console.log('[ACTION_SERVICE] WSonSuccess', response, inTransfer);
    if (!inTransfer) {
      return;
    }
    const { id, entity, topic, backup, type, payload, responseHandlerName, notBeforeThan } = inTransfer;
    this.store.update(state => ({
      ...state,
      inTransfer: null
    }));
    //TODO the standard behavior for success should be moved in a "standard handler" invoked by the injector
    if (response.status === 'success') {
      if (type === 'get' && response.body) {
        const data = IsPaginated<EntityResponse<any>>(response.body) ? response.body.data.entities : response.body.entities;
        runEntityStoreAction(topic === entity ? topic : entity, EntityStoreAction.UpsertManyEntities, upsertManyEntities => upsertManyEntities(data))
      }
      if (!responseHandlerName) {
        return;
      }
      const handler = this.injector.get<ResponseHandler>(handler_dictionary[responseHandlerName]);
      if (handler.success) {
        handler?.success(inTransfer, response);
      }
      return;
    }
    //TODO the standard behavior for failed action should be moved in a "standard handler" invoked by the injector
    if (response.status === 'error' && response.message == 'versioning mismatch') {
      //undo && merge
    } else {
      console.log('[ACTION_SERVICE]', topic, type, response);
      if (backup) {
        runEntityStoreAction(topic === entity ? topic : entity, EntityStoreAction.UpsertManyEntities, setEntities => setEntities([backup]));
      }
      else if (type === 'create' && hasId(payload)) {// && response.error.code !== '23505') {
        runEntityStoreAction(topic === entity ? topic : entity, EntityStoreAction.RemoveEntities, removeEntities => removeEntities(payload.id));
      }
      else {
        console.error('[ACTION_SERVICE] errore non gestibile', response, topic, backup, type, payload);
      }
      // store dei conflitti
    }
    if (response.status === 'error') {
      if (responseHandlerName) {
        const handler = this.injector.get<ResponseHandler>(handler_dictionary[responseHandlerName]);
        if (handler.error) {
          handler?.error(inTransfer, response);
        }
      }
      this._nzNotificationService.error('Errore', response.message);
    }
  }

  onTimeout() {
  }

  sendMessage(topic: Topics, object: string, body: any, callback?: (response: any) => void) {
    if (!callback) {
      return this.socketService.sockets[topic].emit(object, body);
    }
    return this.socketService.sockets[topic].emit(object, body, callback);
  }
}
