import { Injectable } from "@angular/core";
import { IID_OL_SUPPORTO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolSupportoStore } from "./iidOlSupporto.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolSupportoQuery extends AbstractInterfaceDataQuery<IID_OL_SUPPORTO> {
  constructor(protected store: IIDolSupportoStore) {
    super(store);
  }
}
