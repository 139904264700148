import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_APPUNTAMENTO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDolAppuntamentoState extends EntityState<IID_OL_APPUNTAMENTO, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_APPUNTAMENTO })
export class IIDolAppuntamentoStore extends EntityStore<IIDolAppuntamentoState> {
  constructor() {
    super([]);
  }
}
