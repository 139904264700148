import { Injectable } from "@angular/core";
import { ID } from '@common/interfaces/id';
import { IID_GENERIC_OK } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IID_Generic_OkState extends EntityState<IID_GENERIC_OK, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_GENERIC_OK })
export class IID_Generic_OkStore extends EntityStore<IID_Generic_OkState> {
  constructor() {
    super([]);
  }
}
