import { Injectable } from "@angular/core";
import { IID_OLO_DEACTIVATION_PHYSIC } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolDeactivationPhysicStore } from "./iidOlDeactivationPhysic.store";



@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationPhysicQuery extends AbstractInterfaceDataQuery<IID_OLO_DEACTIVATION_PHYSIC> {

  constructor(protected store: IIDolDeactivationPhysicStore) {
    super(store);
  }
}
