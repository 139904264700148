<om-label *ngIf="label" [text]="label" [icon]="icon" [isRequired]="isRequired$ | async"></om-label>
<div class="flex">
  <div *ngIf="preview" class="color-preview-container">
    <div class="color-preview" [ngStyle]="{'background-color':control.value}"></div>
  </div>
  <input type="text" nz-input #ignoredInput [formControl]="control" [cpAlphaChannel]="'always'" [(cpToggle)]="toggle"
    [(colorPicker)]="color" [cpIgnoredElements]="[readonly && ignoredInput]" [readonly]="readonly"
    (colorPickerChange)="changeColor($event)" />
</div>
<om-error [control]="control"></om-error>
