import { Injectable } from '@angular/core';
import { IID_OL_SUPPORTO } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolSupportoStore } from './iidOlSupporto.store';
import { IIDolSupportoQuery } from './iidOlSupporto.query';
@Injectable({
  providedIn: 'root'
})
export class IIDolSupportoService extends CrudService<IID_OL_SUPPORTO, IIDolSupportoStore, IIDolSupportoQuery> {
  constructor(
    protected store: IIDolSupportoStore,
    public query: IIDolSupportoQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_SUPPORTO,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
