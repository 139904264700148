import { Injectable } from "@angular/core";
import { IID_GENERIC_SUSPENSION } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDgenericSuspensionStore } from "./genericSuspension.store";

@Injectable({
  providedIn: 'root'
})
export class IIDgenericSuspensionQuery extends AbstractInterfaceDataQuery<IID_GENERIC_SUSPENSION> {
  constructor(protected store: IIDgenericSuspensionStore) {
    super(store);
  }
}
