import { ProjectScopeState, ProjectScopeStore } from './projectScope.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProjectQuery } from '../project/project.query';
import { switchMap } from 'rxjs/operators';
import { IProjectScope } from '@common/interfaces/projectScope';

@Injectable({
  providedIn: 'root'
})
export class ProjectScopeQuery extends QueryEntity<ProjectScopeState> {
  all$ = this.selectAll();

  constructor(protected store: ProjectScopeStore, private projectQuery: ProjectQuery) {
    super(store);
  }

  active$ = this.projectQuery.selectActiveId().pipe(
    switchMap(id => {
      return this.selectEntity(item => item.projectId === id);
    })
  );


  getByProjectId(projectId: string): IProjectScope {
    return this.getAll().find(c => c.projectId === projectId);
  }
}
