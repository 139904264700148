import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_OK } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolEspletatoOkStore } from "./espletatoOk.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoOkQuery extends AbstractInterfaceDataQuery<IID_OL_ESPLETATO_OK> {

  constructor(protected store: IIDolEspletatoOkStore) {
    super(store);
  }
}
