import { Injectable } from '@angular/core';
import { MaintenanceComponent } from './maintenance.component';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { SysMessageService } from '@ep-om/project/service/sysMessage.service';
import { AuthQuery } from '@ep-om/project/auth/auth.query';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate, CanDeactivate<MaintenanceComponent> {
  constructor(
    private router: Router,
    private sysMessageService: SysMessageService,
    private authQuery: AuthQuery) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('entro nel mintenance guarda??????');
    
    let inMaintenance = this.sysMessageService.getMaintenance();
    if(inMaintenance && route.routeConfig.path === 'maintenance') {
      return true;
    } else if (!inMaintenance && route.routeConfig.path === 'maintenance') {
      return false;
    } else if (inMaintenance) {
      this.router.navigate(['maintenance']);
      return false;
    } else {
      return true;
    }
  }

  canDeactivate(component: MaintenanceComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let inMaintenance = this.sysMessageService.getMaintenance();
    if(inMaintenance) {
      return false
    } else {
      return true;
    } 
  }
  
}
