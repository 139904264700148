import { Injectable } from "@angular/core";
import { IID_GR_TASKS } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDGrTasksStore } from "./iidGRTasks.store";

@Injectable({
  providedIn: 'root'
})
export class IIDGrTasksQuery extends AbstractInterfaceDataQuery<IID_GR_TASKS> {

  constructor(protected store: IIDGrTasksStore) {
    super(store);
  }

}
