import { Injectable } from "@angular/core";
import { IID_EP_TICKET } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDepTicketStore } from "./iidEpTicket.store";

@Injectable({
  providedIn: 'root'
})
export class IIDepTicketQuery extends AbstractInterfaceDataQuery<IID_EP_TICKET> {
  constructor(protected store: IIDepTicketStore) {
    super(store);
  }
}
