import { Component } from "@angular/core";
import { Observable, Subject, of, throwError } from "rxjs";
import { bufferTime, switchMap, timeout } from "rxjs/operators";

type Message<T = unknown> = {
  topic: string,
  type: 'request' | 'broadcast' | 'answer';
  sender: string,
  requestId: string | null,
  payload: T
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

@Component({
  template: `
  <input type="text" ([ngModel])="inputRequest"><button (click)="sendRequest()">Request</button><br>
  <input type="text" ([ngModel])="inputBroadcast"><button (click)="send()">broadcast</button><br>
  <h1>{{iAmMaster}}</h1>
  <label>My Roll:</label>{{myRoll$ | async}}<br>
  <h1>answers</h1>
  <div *ngFor="let answer of answers"><pre>{{answer | json}}<br></pre></div>
  <h1>broadcasts</h1>
  <div *ngFor="let broadcast of broadcasts"><pre>{{broadcast | json}}<br></pre></div>
  `
})
export class MainTabTestComponent {
  iAmMaster = 'I am NOT master';
  id = crypto.randomUUID();
  myRoll = getRandomInt(0, 100);
  myRoll$ = of(this.myRoll);
  inputBroadcast: string = null;
  inputRequest: string = null;
  broadcasts = []
  answers = [];
  private channel = new BroadcastChannel('MainTabChallenger');
  private requests: Record<string, Subject<unknown>> = {};

  constructor() {
    this.channel.onmessage = (event) => {
      const message: Message = event.data;
      if (message.sender === this.id) {
        return;
      }
      switch (message.type) {
        case 'request': {
          console.log('sending response');
          this._sendResponse(message, this.myRoll)
          break;
        }
        case 'answer': {
          this.requests[message.requestId]?.next(message.payload);
          break;
        }
        case 'broadcast': {
          this.broadcasts.push(message);
          break;
        }
      }
    }
    setTimeout(async () => {
      const answers = await this._sendRequest('WHO is the boss', {myRoll: this.myRoll})
      this.answers.push(answers);
      // if (answers.length === 0) {
      //   this.iAmMaster = 'I AM Master';
      //   return;
      // }
      this.iAmMaster = answers.some(a => a < this.myRoll) ? 'I am NOT master' : 'I AM Master';
    }, 3000)
  }

  _sendResponse(message: Message, response: any) {
    this._sendMessage({
      topic: message.topic,
      sender: this.id,
      requestId: message.requestId,
      type: 'answer',
      payload: response,
    });
  }

  async sendRequest() {
    const response = await this._sendRequest('[CHALLENGE]', this.inputRequest);
    this.answers.push(response);
    console.log('answer')
  }

  _sendRequest(topic: string, payload: any): Promise<any[]> {
    const requestId = crypto.randomUUID();
    this._sendMessage({
      topic,
      sender: this.id,
      requestId,
      type: 'request',
      payload
    });
    this.requests[requestId] = new Subject();
    return new Promise((resolve, reject) => {
      this.requests[requestId].pipe(
        bufferTime(5000),
      ).subscribe({
        next: response => resolve(response),
        error: err => reject(err)
      })
    });
  }

  send() {
    this._sendMessage({
      payload: this.inputBroadcast,
      sender: this.id,
      requestId: null,
      topic: '[BROADCAST]',
      type: 'broadcast',
    })
  }

  _sendMessage(message: Message) {
    this.channel.postMessage(message);
  }

  start() {}

}
