export type WsStatus = 'error' | 'success';
export interface WsACKResponse<T> {
    message?: string,
    status: WsStatus,
    body?: T,
    exception?: string,
}

export type WsACKCallback<T = void> = (response?: WsACKResponse<T>) => any

export interface EntityResponse<T = any> {
  entityName: string,
  entities: T,
}

export function isEntityResponse<T>(x: any): x is EntityResponse<T> {
  return 'entityName' in x && 'entities' in x;
}
