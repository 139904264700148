<button 
  [type]="type"
  [ngClass]="['btn', className, isActive ? 'is-active': '', content?.innerHTML?.trim() ? '': 'icon-only']"
  [disabled]="disabled"
  [ngStyle]="_buttonStyle"
>
  <svg-icon *ngIf="!isWorking && icon"
            [name]="icon"
            [size]="iconSize"
            [fill]="iconFill">
  </svg-icon>
  <svg-icon *ngIf="isWorking"
            name="spin"
            class="spinner anticon-spin"
            [size]="iconSize"
            [fill]="iconFill">
  </svg-icon>
  <span #content
        [class.with-padding]="isWorking || icon">
      <ng-content></ng-content>
  </span>
</button>
