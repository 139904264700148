import { Injectable } from "@angular/core";
import { IID_EP_RISPOSTA } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDEpRispostaStore } from "./iidEPRisposta.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDEpRispostaQuery extends AbstractInterfaceDataQuery<IID_EP_RISPOSTA> {
  
    constructor(protected store: IIDEpRispostaStore) {
      super(store);
    }
  }