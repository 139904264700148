import { Injectable } from "@angular/core";
import { WorkFlowSettings } from "@common/interfaces/workflow";
import { ID, QueryEntity } from "@datorama/akita";
import { sha1 } from "object-hash";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { WorkflowState, WorkflowStore } from "./workflow.store";

@Injectable({
  providedIn: 'root'
})
export class WorkflowQuery extends QueryEntity<WorkflowState> {
  all$ = this.selectAll();

  constructor(protected store: WorkflowStore) {
    super(store);
  }

  selectWorkflowSettingsByWorkflowId$(workflowId: string): Observable<WorkFlowSettings>{
    return this.selectEntity(workflowId)
    .pipe(
      distinctUntilChanged((x,y) => sha1(x?.settings) === sha1(y?.settings)),
      map(workflow => workflow?.settings)
    );
  }

}
