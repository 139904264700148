import { Injectable } from "@angular/core";
import { IIDNetworkItemsStore } from "./iidNetworkItems.store";
import { IID_NETWORK_ITEMS } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { ProjectQuery } from "../project/project.query";
import { IssueQuery } from "../issue/issue.query";

@Injectable({
  providedIn: 'root'
})
export class IIDNetworkItemsQuery extends AbstractInterfaceDataQuery<IID_NETWORK_ITEMS> {

  constructor(
    protected store: IIDNetworkItemsStore,
    private projectQuery: ProjectQuery,
    private issueQuery: IssueQuery,
  ) {
    super(store);
  }

  getIIDNetworkItemsNotInFinalStatesByProjectId(projectId: string): IID_NETWORK_ITEMS[] {
    const projectFinalStates = this.projectQuery.getWorkflowSettingsByProjectId(projectId).finalStates;
    const { positive, negative } = projectFinalStates;

    return this.getAll({
      filterBy: [
        iidNetworkItems => iidNetworkItems.projectId === projectId,
        iidNetworkItems => ![...positive, ...negative].includes(this.issueQuery.getEntity(iidNetworkItems.issueId).stateId),
      ]
    });
  }

}
