import { Injectable } from "@angular/core";
import { IID_OL_SOLLECITO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolSollecitoStore } from "./iidOlSollecito.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolSollecitoQuery extends AbstractInterfaceDataQuery<IID_OL_SOLLECITO> {
  constructor(protected store: IIDolSollecitoStore) {
    super(store);
  }
}
