import { Injectable } from "@angular/core";
import { IID_GENERIC_OK } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IID_Generic_OkStore } from "./genericOk.store";

@Injectable({
  providedIn: 'root'
})
export class IID_Generic_OkQuery extends AbstractInterfaceDataQuery<IID_GENERIC_OK> {
  constructor(protected store: IID_Generic_OkStore) {
    super(store);
  }
}
