import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'om-error',
  templateUrl: './om-error.component.html',
  styleUrls: ['./om-error.component.scss']
})
export class OmErrorComponent {
  @Input() control: FormControl;
  
  constructor() {}
}
