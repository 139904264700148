import { Injectable } from "@angular/core";
import { IID_OL_RISOLUZIONE } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRisoluzioneStore } from "./iidOlRisoluzione.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRisoluzioneQuery extends AbstractInterfaceDataQuery<IID_OL_RISOLUZIONE> {
  constructor(protected store: IIDolRisoluzioneStore) {
    super(store);
  }
}
