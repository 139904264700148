import { Injectable } from "@angular/core";
import { TypeHelpers } from "@common/utils/type.helpers";
import { debounceTime, map, refCount, shareReplay, startWith, tap } from "rxjs/operators";
import { LastUpdateInfo, LastUpdateStatus } from "./lastUpdate.model";
import { LastUpdateQuery } from "./lastUpdate.query";
import { LastUpdateStore } from "./lastUpdate.store";

@Injectable({
  providedIn: 'root'
})
export class LastUpdateService {
  constructor(
    private _store: LastUpdateStore,
    public query: LastUpdateQuery,
  ) {
  }

  calculateChildKey(projectId: string, childStoreName: string) {
    return `PRJ_${projectId}_${childStoreName}`;
  }

  /**
   * Sets the Store in 'first_sync' state
   * @param storeName 
   * @param projectId 
   */
  setStoreInSync(storeName: string, projectId?: string): void {
    this._setStoreLiveOrInSync(storeName, 'first_sync', projectId);
  }

  /**
   * Sets the Store in 'live' state
   * @param storeName 
   * @param projectId 
   */
  setStoreLive(storeName: string, projectId?: string): void {
    this._setStoreLiveOrInSync(storeName, 'live', projectId);
  }

  private _setStoreLiveOrInSync(storeName: string, status: LastUpdateStatus, projectId?: string) {
    const key = projectId ? this.calculateChildKey(projectId, storeName) : storeName;
    this._store.update(state => ({
      ...state,
      [key]: {
        ...state[key] || {},
        status,
        remaining: status === 'live' ? 0 : state[key]?.remaining,
      }
    }))
  }

  isStoreLive$(storeName: string, projectId?: string) {
    console.log('checking store', storeName);
    const key = projectId ? this.calculateChildKey(projectId, storeName) : storeName;
    return this.query.select(key).pipe(map(val => val?.status === 'live'));
  }

  isEverythingLive$ = this.query.select().pipe(
    map(lastUpdates => {
      console.log('LAST UPDATE HAS', Object.values(lastUpdates).length, 'stores');
      return !Object.values(lastUpdates).some(store => store?.status === null || store?.status === 'first_sync')
    }),
    startWith(false),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  isStoreLive(storeName: string, projectId?: string) {
    const key = projectId ? this.calculateChildKey(projectId, storeName) : storeName;
    return this.query.getValue()[key]?.status === 'live';
  }

  setLastUpdate(storeName: string, updateStatus: TypeHelpers.WithOptional<Omit<LastUpdateInfo, 'status'>, 'remaining'>) {
    return this._store.update(state => ({
      ...state,
      [storeName]: {
        ...state[storeName] || { status: 'first_sync' },
        ...updateStatus,
      }
    }));
  }


  cleanChildLastUpdate(projectId: string) {
    const state = this._store.getValue();
    let newState = { ...state }
    for (const key in newState) {
      if (key.startsWith(`PRJ_${projectId}_`)) {
        delete newState[key];
      }
    }
    this._store._setState(newState);
  }

  setChildLastUpdate(projectId: string, childStoreName: string, updateStatus: TypeHelpers.WithOptional<Omit<LastUpdateInfo, 'status'>, 'remaining'>) {
    const key = this.calculateChildKey(projectId, childStoreName);
    this._store.update(state => ({
      ...state,
      [key]: {
        ...state[key] || { status: 'first_sync' },
        ...updateStatus,
      }
    })
    );
  }

}
