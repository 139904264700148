import { Injectable } from "@angular/core";
import { IID_OL_ACQUISITO_KO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolAquisitiKoStore } from "./aquisitiKo.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAquisitiKoQuery extends AbstractInterfaceDataQuery<IID_OL_ACQUISITO_KO> {

  constructor(protected store: IIDolAquisitiKoStore) {
    super(store);
  }
}
