import { Injectable } from "@angular/core";
import { IIssueTypeWorkflow } from "@common/interfaces/issueTypeWorflow";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IssueTypeWorkflowState extends EntityState<IIssueTypeWorkflow, ID> { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_TYPE_WORKFLOWS })
export class IssueTypeWorkflowStore extends EntityStore<IssueTypeWorkflowState> {
    constructor() {
        super([]);
    }
}
