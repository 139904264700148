export function getSelectOptionsFromEnum(object: any, ordered?: boolean, orderNumericString?: boolean) {
  if (!object) {
    return [];
  }
  let selectOptions = Object.keys(object).map((option: any) => {
    if (isNaN(option)) {
      const labelValue = option.replace(/_/g, ' ');
      return ({ label: labelValue, value: object[option] })
    } else {
      return ({ label: object[option].replace(/_/g, ' '), value: option })
    }
  });
  if (ordered) {
    selectOptions.sort((a, b) => a.label - b.label);
  }
  if (orderNumericString) {
    selectOptions.sort((a: any, b: any) => a.label.localeCompare(b.label, undefined, { numeric: true }));
  }

  return selectOptions;
}
