


<div class="mb-6">
  <nz-form-label [nzSm]="6" [nzXs]="24" [nzFor]="formControl.value" [nzRequired]="this.field.props?.required">{{ to.label }}</nz-form-label>
  <div class="container">
    <i class="icon-preview" nz-icon [nzType]="formControl.value" nzTheme="outline"></i>
    <nz-select class="w-full" [nzDisabled]="this.props.disabled" [formControl]="formControl" nzShowSearch nzAllowClear>
      <nz-option *ngFor="let item of this.props.options" [nzValue]="item.value" [nzLabel]="item.label" ></nz-option>
    </nz-select>
  </div>
</div>
