import { Injectable } from "@angular/core";
import { IID_EP_ASSET } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDepAssetsStore } from "./iidEPAssets.store";

@Injectable({
  providedIn: 'root'
})
export class IIDepAssetsQuery extends AbstractInterfaceDataQuery<IID_EP_ASSET> {

  constructor(protected store: IIDepAssetsStore) {
    super(store);
  }
}
