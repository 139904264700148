import { Injectable } from "@angular/core";
import { IID_ISSUE_LINKED } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDIssueLinkedStore } from "./iidIssueLinked.store";

@Injectable({
  providedIn: 'root'
})
export class IIDIssueLinkedQuery extends AbstractInterfaceDataQuery<IID_ISSUE_LINKED> {
  constructor(protected store: IIDIssueLinkedStore) {
    super(store);
  }
}
