import { Injectable } from "@angular/core";
import { IID_OL_RIFERIMENTI_UI } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRiferimentiUiStore } from "./iidOlRiferimentiUi.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRiferimentiUiQuery extends AbstractInterfaceDataQuery<IID_OL_RIFERIMENTI_UI> {
  constructor(protected store: IIDolRiferimentiUiStore) {
    super(store);
  }
}
