import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-select',
  templateUrl: './formly-select.component.html',
  styleUrls: ['./formly-select.component.scss']
})
export class FormlySelectComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    console.log(this.field)
  }
  
}
