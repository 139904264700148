import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  set(key: string, val: any) {
    localStorage.setItem(key, JSON.stringify(val));
  }

  get<T>(key: string): T | null
  get(key: string): string | null {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  updateInTransaction<T>(key: string, reducer: (val: T) => T) {
    const val = this.get<T>(key);
    const newVal = reducer(val);
    this.set(key, newVal);
  }

}
