import { Injectable } from "@angular/core";
import { IID_OL_CONDOMINIO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolCondominioStore } from "./iidOlCondominio.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolCondominioQuery extends AbstractInterfaceDataQuery<IID_OL_CONDOMINIO> {

  constructor(protected store: IIDolCondominioStore) {
    super(store);
  }
}
