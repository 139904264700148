import { Injectable } from '@angular/core';
import { IID_OL_RISORSA_PNI } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolRisorsaPniStore } from './iidOlRisorsaPni.store';
import { IIDolRisorsaPniQuery } from './iidOlRisorsaPni.query';
@Injectable({
  providedIn: 'root'
})
export class IIDolRisorsaPniService extends CrudService<IID_OL_RISORSA_PNI, IIDolRisorsaPniStore, IIDolRisorsaPniQuery> {
  constructor(
    protected store: IIDolRisorsaPniStore,
    public query: IIDolRisorsaPniQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_RISORSA_PNI,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }
}
