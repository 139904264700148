import { Injectable } from "@angular/core";
import { NotificationsStore } from "./notifications.store";
import { NotificationsOfAnIssue, NewsMoreInfo } from "@common/interfaces/notifications";
import { NotificationsQuery } from "./notifications.query";

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(
    private store: NotificationsStore,
    private query: NotificationsQuery,
    ) { }

  add(payload: NewsMoreInfo) {
    for (let issueId in payload) {
      if (!this.query.getEntity(issueId)) {
        this.store.add(payload[issueId]);
        continue;
      };
      this.store.update(issueId, state => ({
        ...state,
        projectId: payload[issueId].projectId,  // Serve nel caso la issue viene cambiata di progetto
        issue: payload[issueId].issue,
        events: {
          ...state.events,
          ...payload[issueId].events
        }
      }))
    }
  };

  removeNotifsByIssue(issueId: string) {
    this.store.remove(issueId);
  }

  removeNotifsByProject(projectId: string) {
    if(!projectId) {
      return;
    }
    let notifs: NotificationsOfAnIssue[] = this.query.getAll().filter(notif => notif.projectId === projectId);
    this.store.remove(notifs.map(el => el.issueId));
  }
}
