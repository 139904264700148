import { Injectable } from "@angular/core";
import { IID_OL_OLOREQUEST } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolOloRequestStore } from "./iidOlOloRequest.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolOloRequestQuery extends AbstractInterfaceDataQuery<IID_OL_OLOREQUEST> {
  constructor(protected store: IIDolOloRequestStore) {
    super(store);
  }
}
