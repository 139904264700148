import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IUser } from "@common/interfaces/user";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface UserState extends EntityState<IUser, ID> { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.USERS })
export class UserStore extends EntityStore<UserState> {
    constructor() {
        super([]);
    }
}
