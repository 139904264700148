import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IIssueType } from "@common/interfaces/issueType";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IssueTypeState extends EntityState<IIssueType, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_TYPES })
export class IssueTypeStore extends EntityStore<IssueTypeState> {
  constructor() {
    super([]);
  }
}
