import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_EP_TICKET } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIdEpTicketState extends EntityState<IID_EP_TICKET, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_EP_TICKET })
export class IIDepTicketStore extends EntityStore<IIdEpTicketState> {
  constructor() {
    super([]);
  }
}
