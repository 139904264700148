import { Injectable } from "@angular/core";
import { IIDGrResourcesMapStore } from "./iidGRResourcesMap.store";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IID_GR_RESOURCES_MAP } from "@common/interfaces/interfaceData";

@Injectable({
  providedIn: 'root'
})
export class IIDGrResourcesMapQuery extends AbstractInterfaceDataQuery<IID_GR_RESOURCES_MAP> {

  constructor(protected store: IIDGrResourcesMapStore) {
    super(store);
  }

}
