import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_ACCETTATO_KO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDolAccettatoKoState extends EntityState<IID_OL_ACCETTATO_KO, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_ACCETTATO_KO })
export class IIDolAccettatoKoStore extends EntityStore<IIDolAccettatoKoState> {
  constructor() {
    super([]);
  }
}
