import { Injectable } from "@angular/core";
import { IIssueState } from '@common/interfaces/issueState';
import { QueryEntity } from "@datorama/akita";
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IssueStateWorkflowQuery } from "../issueStateWorkflow/issueStateWorkflow.query";
import { IssueStateState, IssueStateStore } from "./issueState.store";

@Injectable({
  providedIn: 'root'
})
export class IssueStateQuery extends QueryEntity<IssueStateState> {
  all$ = this.selectAll();

  constructor(protected store: IssueStateStore, private IssueStateWorkflowQuery: IssueStateWorkflowQuery) {
    super(store);
  }

  getById$(id: string) {
    return this.selectEntity(id)
  }

  selectByIds$(ids: string[]) {
    return this.selectAll({
      filterBy: issueState => ids.includes(issueState.id)
    });
  }

  getByWorkflow(id: string): IIssueState[] {
    const issueStates = this.getAll();
    const issueStateWorkflows = this.IssueStateWorkflowQuery.getByWorkflow(id);
    const issueStateIds = issueStateWorkflows.map(isw => isw.issueStateId);
    return issueStates.filter(issueState => issueStateIds.includes(issueState.id)).sort((a, b) => {
      return issueStateWorkflows.find(isw => isw.issueStateId === a.id).order - issueStateWorkflows.find(isw => isw.issueStateId === b.id).order
    });
  }

  public selectByWorkflow$(id: string): Observable<IIssueState[]> {
    return combineLatest([
      this.selectAll(),
      this.IssueStateWorkflowQuery.selectByWorkflow$(id)
    ]).pipe(
      map(([issueStates, issueStateWorkflows]) => {
        const issueStateIds = issueStateWorkflows.map(isw => isw.issueStateId)
        return issueStates.filter(issueState => issueStateIds.includes(issueState.id)).sort((a, b) => {
          return issueStateWorkflows.find(isw => isw.issueStateId === a.id).order - issueStateWorkflows.find(isw => isw.issueStateId === b.id).order
        })
      })
    );
  }
}
