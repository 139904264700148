import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { AppointmenteStore, AppointmentState } from './appointment.store';
import { ascSort, sortByCreatedAt } from '@ep-om/utils/sort';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: (a: { createdAt: string }, b: { createdAt: string }) => sortByCreatedAt(a, b)(ascSort)
})
export class AppointmentQuery extends QueryEntity<AppointmentState> {
  constructor(protected store: AppointmenteStore) {
    super(store);
  }

  selectAppointmentsByIssueId$(issueId: string) {
    return this.selectAll({
      filterBy: appointment => appointment.issueId === issueId
    })
  }

  getAppointmentsByIssueId(issueId: string) {
    return this.getAll({
      filterBy: appointment => appointment.issueId === issueId
    })
  }

  getAppointmentsByUserId(userId: string) {
    return this.getAll({
      filterBy: appointment => appointment.userId === userId
    })
  }
}
