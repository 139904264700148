import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_ISSUE_LINKED } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDIssueLinkedState extends EntityState<IID_ISSUE_LINKED, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_ISSUE_LINKED })
export class IIDIssueLinkedStore extends EntityStore<IIDIssueLinkedState> {
  constructor() {
    super([]);
  }
}
