import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IIssueTypeInterface } from "@common/interfaces/issueTypeInterface"
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IssueTypeInterfaceState extends EntityState<IIssueTypeInterface, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_TYPE_INTERFACES })
export class IssueTypeInterfaceStore extends EntityStore<IssueTypeInterfaceState> {
  constructor() {
    super([]);
  }
}
