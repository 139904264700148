import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss']
})
export class SelectAutocomplete implements OnInit {
  @Input() control: FormControl;
  @Input() options$: Observable<string[]>;
  @Input() placeholder: string = '';

  constructor() {}

  ngOnInit(): void {
    // this.options$ = this.projectService.query.categories$;
  }
}
