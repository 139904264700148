import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { NotificationsOfAnIssue } from '@common/interfaces/notifications';
import { ID } from '@common/interfaces/id';

export interface NotificationsState extends EntityState<NotificationsOfAnIssue, ID>{ };

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Notifications', idKey: 'issueId', resettable: true })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}
