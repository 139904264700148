<div class="flex" [ngClass]="formControl.errors && formControl.dirty ? 'mb-0' : 'mb-6'">
  <om-date-input class="w-full"
    [control]="formControl"
    [disabled]="props.disabled"
    [placeholder]="props.placeholder"
    [label]="props.label"
    [dateFormat]="props.format"
    [showTime]="props.showTime"
  ></om-date-input>
</div>
