import { Injectable } from "@angular/core";
import { IID_OL_CHECKLIST } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolChecklistQuery } from "./iidOlChecklist.query";
import { IIDolChecklistStore } from "./iidOlChecklist.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDolChecklistService extends CrudService<IID_OL_CHECKLIST, IIDolChecklistStore, IIDolChecklistQuery> {
    constructor(
      protected store: IIDolChecklistStore,
      public query: IIDolChecklistQuery,
      protected actionService: ActionService,
      protected updateStrategy: ProjectEntityStoreStrategy) {
      super(
        Topics.IID_OL_CHECKLIST,
        store,
        query,
        actionService,
        updateStrategy
      );
    }
  
  }
