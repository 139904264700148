import { Injectable } from '@angular/core';
import { IIDGrResourcesMapQuery } from './iidGRResourcesMap.query';
import { IIDGrResourcesMapStore } from './iidGRResourcesMap.store';
import { IID_GR_RESOURCES_MAP } from '@common/interfaces/interfaceData';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../crudService';
import { environment } from 'src/environments/environment';
import { FeatureCollection } from 'geojson';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IIDGrResourcesMapService extends CrudService<IID_GR_RESOURCES_MAP, IIDGrResourcesMapStore, IIDGrResourcesMapQuery> {

  constructor(
    protected store: IIDGrResourcesMapStore,
    public query: IIDGrResourcesMapQuery,
    private http: HttpClient
  ) {
    super(
      null,
      store,
      query,
      null,
      null
    );
  }

  getGeoJsonFromGeoserverByActivityId$(typeName: string, activityId: string): Observable<FeatureCollection> {
    return this.http.get<FeatureCollection>(`${environment.apiUrl}/api/geoserver/wfs`, { params: { 'typename': typeName, 'filter': `id_activity=${activityId}` } }).pipe(
      filter(geojson => !!geojson),
      tap(geojson => console.log(`features geroserver filter by id_activity=${activityId}`, geojson))
    );
  }

}
