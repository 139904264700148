import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_OK_OF } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolEspletatoOkOFStore } from "./espletatoOkOF.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoOkOFQuery extends AbstractInterfaceDataQuery<IID_OL_ESPLETATO_OK_OF> {

  constructor(protected store: IIDolEspletatoOkOFStore) {
    super(store);
  }
}
