import { Injectable } from '@angular/core';
import { IIDolRisoluzioneStore } from './iidOlRisoluzione.store';
import { IID_OL_RISOLUZIONE } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolRisoluzioneQuery } from './iidOlRisoluzione.query'
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolRisoluzioneService extends CrudService<IID_OL_RISOLUZIONE, IIDolRisoluzioneStore, IIDolRisoluzioneQuery> {
  constructor(
    protected store: IIDolRisoluzioneStore,
    public query: IIDolRisoluzioneQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_RISOLUZIONE,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
