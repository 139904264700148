import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-select-autocomplete',
  templateUrl: './formly-select-autocomplete.component.html',
  styleUrls: ['./formly-select-autocomplete.component.scss']
})
export class FormlySelectAutocompleteComponent extends FieldType {
}
