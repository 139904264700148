import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import * as Sentry from '@sentry/angular';
import { NzProgressModule } from 'ng-zorro-antd/progress';
//Localizzazione Angular
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it')

/** Localizzazione ng-zorro-antd i18n **/
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { it_IT, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { QueryEntity, resetStores } from '@datorama/akita';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { MarkdownModule } from 'ngx-markdown';
import { IssueDetailsDirective } from './core/directives/issue-details.directive';
import { SnowModule } from './core/snow/snow.module';
import { FirefoxErrorComponent } from './errors/firefox-error/firefox-error.component';
import { MainTabTestComponent } from './main-tab-test.component';
import { DATA_SOURCE } from './project/data-sources/datasource.abstract';
import { PlayersDataSource } from './project/data-sources/player.datasource';
import { TeamsDataSource } from './project/data-sources/teams.datasource';
import { UserDataSource } from './project/data-sources/user.datasource';
import { CheckboxFormlyComponent } from './project/formly-wrappers/checkbox-formly/checkbox-formly.component';
import { DatePickerFormlyComponent } from './project/formly-wrappers/date-picker/date-picker-formly.component';
import { FormlyAntIconSelectComponent } from './project/formly-wrappers/formly-ant-icon-select/formly-ant-icon-select.component';
import { FormlyColorPickerComponent } from './project/formly-wrappers/formly-color-picker/formly-color-picker.component';
import { FormlyIconSelectComponent } from './project/formly-wrappers/formly-icon-select/formly-icon-select.component';
import { FormlyInterfaceVisibilityComponent } from './project/formly-wrappers/formly-interface-visibility/formly-interface-visibility.component';
import { FormlySelectAutocompleteComponent } from './project/formly-wrappers/formly-select-autocomplete/formly-select-autocomplete.component';
import { FormlySelectComponent } from './project/formly-wrappers/formly-select/formly-select.component';
import { FormlyUserSelectComponent } from './project/formly-wrappers/formly-user-select/formly-user-select.component';
import { MultipleSelectComponent } from './project/formly-wrappers/multiple-select/multiple-select.component';
import { RepeatSectionTypeComponent } from './project/formly-wrappers/repeat-section/repeat-section.type';
import { RepeatComponent } from './project/formly-wrappers/repeat/repeat.component';
import { SingleComponent } from './project/formly-wrappers/single/single.component';
import { FormlySwitchComponent } from './project/formly-wrappers/switch/formly-switch.component';
import { TimePickerFormlyComponent } from './project/formly-wrappers/time-picker/time-picker-formly.component';
import { FormlyValidators as FV } from './project/formly-wrappers/validators/formly.validators';
import { AppEventsService } from './project/service/appEvents.service';
import { ClientMessagesService } from './project/service/clientMessages.service';
import { LocalStorageService } from './project/service/localstorage.service';
import { APPOINTMENT_REMOTE_GET, AppointmentRemoteGetByUsersHandler, ISSUE_CREATION, IssueCreationHandler } from './project/state/action/handlers/handlers';
import { IssueQuery } from './project/state/issue/issue.query';
import { MetaInterfaceServiceProvider } from './project/state/metaInterfaces/metaInterfaces.provider.service';
import { ProjectQuery } from './project/state/project/project.query';
import { ProjectScopeQuery } from './project/state/projectScope/projectScope.query';
import { WorkflowQuery } from './project/state/workflow/workflow.query';
import { getSaver, SAVER } from './utils/saver.provider';

const queryString = window.location.search;
// Crea un oggetto URLSearchParams dalla query string
const urlParams = new URLSearchParams(queryString);
// Ottieni il valore di un parametro specifico
const DELAY = parseInt(urlParams.get('delay') || "1000");

const antDesignIcons = AllIcons as { [key: string]: IconDefinition; };
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const STORAGE_INFO_KEY = new InjectionToken<string>('STORAGE_INFO_KEY');

export type StorageInfo = {
  name: string,
  hasValue: boolean,
  isMeta: boolean,
  idKey: string,
}

const checkIfStoreHasValues = async (query: QueryEntity<any>) => {
  const start = performance.now();
  console.log('checking if store has values', query);
  while (query.getValue()?.ids.length === 0) {
    const now = performance.now();
    if ((now - start) > 10000) {
      throw new Error('empty store');
    }
    await delay(1000);
  }
}

const APP_INIT_FACTORY = (
  storageKey: string,
  interfaceProviderService: MetaInterfaceServiceProvider,
  storage: LocalStorageService,
  projectQuery: ProjectQuery,
  workflowQuery: WorkflowQuery,
  issueQuery: IssueQuery,
  projectScopeQuery: ProjectScopeQuery,
) => async () => {
  await delay(100);
  console.log('[APP INIT FACTORY]');
  const getQuery = (storeName: string) => {
    if (storeName === 'Projects') return projectQuery;
    if (storeName === 'Workflows') return workflowQuery;
    if (storeName === 'Issues') return issueQuery;
    if (storeName === 'ProjectScopes') return projectScopeQuery;
    return interfaceProviderService.getQuery(storeName);
  };
  const stores = storage.get<StorageInfo[]>(storageKey);
  if (!stores) {
    console.log('[APP INIT FACTORY] not sotres found - initing storage key');
    storage.set(storageKey, []);
    return;
  }
  for (const store of stores) {
    console.log('[APP INIT FACTORY] sotres found - loading');
    if (store.isMeta) {
      console.log(`[APP INIT FACTORY] loading meta interface ${store.name}`);
      interfaceProviderService.loadStores([{ name: store.name, idKey: store.idKey }]);
    }
    if (store.hasValue) {
      console.log(`[APP INIT FACTORY] store ${store.name} has values`);
      const query = getQuery(store.name);
      if (!query) continue;
      console.log(`[APP INIT FACTORY] checking...`);
      try {
        await checkIfStoreHasValues(query)
      } catch (e) {
        resetStores();
        window.location.reload;

      }
      console.log(`[APP INIT FACTORY] OK!`);
    }
  }
}

@NgModule({
  declarations: [AppComponent, IssueDetailsDirective, FirefoxErrorComponent, MainTabTestComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NzNotificationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSpinModule,
    NzUploadModule,
    NzProgressModule,
    NzImageModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('service-worker.js', {
      //enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      types: [
        { name: 'user-select-formly', component: FormlyUserSelectComponent },
        { name: 'select-autocomplete', component: FormlySelectAutocompleteComponent },
        { name: 'multiple-select', component: MultipleSelectComponent },
        { name: 'repeat', component: RepeatComponent },
        { name: 'checkbox-formly', component: CheckboxFormlyComponent },
        { name: 'switch', component: FormlySwitchComponent },
        { name: 'custom-checkbox', component: CheckboxFormlyComponent },
        { name: 'date', component: DatePickerFormlyComponent },
        { name: 'time', component: TimePickerFormlyComponent },
        { name: 'formly-select', component: FormlySelectComponent },
        { name: 'formly-icon-select', component: FormlyIconSelectComponent },
        { name: 'formly-ant-icon-select', component: FormlyAntIconSelectComponent },
        { name: 'formly-color', component: FormlyColorPickerComponent },
        { name: 'repeat-section', component: RepeatSectionTypeComponent },
        { name: 'interface-visibility', component: FormlyInterfaceVisibilityComponent },
      ],
      wrappers: [
        { name: 'single', component: SingleComponent },
      ],
      validators: [
        { name: "email-validation", validation: FV.EmailValidator },
        { name: "domain-validation", validation: FV.DomainValidator },
        { name: 'fieldMatch', validation: FV.fieldMatchValidator },
        { name: 'workflow-states', validation: FV.workflowStatesValidator },
        { name: 'workflow-issueTypes', validation: FV.workflowIssueTypesValidator },
        { name: 'issueTypes-interfaces', validation: FV.issueTypesInterfacesValidator },
        { name: 'phone-validation', validation: FV.phoneValidation },
        { name: 'username-validation', validation: FV.UsernameValidator }
      ],
      validationMessages: [
        { name: "email-validation", message: FV.EmailValidatorMessage },
        { name: "domain-validation", message: FV.DomainValidatorMessage },
        { name: 'minlength', message: FV.minValidationMessage },
        { name: 'maxlength', message: FV.maxValidationMessage },
        { name: 'required', message: 'Il campo non può essere vuoto' },
        { name: 'unique-state', message: 'Lo stato già esiste...' },
        { name: 'unique-issueType', message: 'Il tipo di task già esiste...' },
        { name: 'unique-interface', message: "L'interfaccia già esiste..." },
        { name: 'phone-validation', message: "Il numero di telefono non è valido..." },
        { name: "username-validation", message: FV.UsernameValidatorMessage },
      ]
    }),
    FormlyNgZorroAntdModule,
    SnowModule,
    NzModalModule,
    NzAlertModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    {
      provide: STORAGE_INFO_KEY,
      useValue: '_EP-STORES_'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: APP_INIT_FACTORY,
      deps: [
        STORAGE_INFO_KEY,
        MetaInterfaceServiceProvider,
        LocalStorageService,
        ProjectQuery,
        WorkflowQuery,
        IssueQuery,
        ProjectScopeQuery,
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }
    },
    { provide: SAVER, useFactory: getSaver },
    /* {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    }, */
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (issueStore: IssueStore, userStore: UserStore, projectStore: ProjectStore) => async () => {
    //     console.log("User store", Object.values(userStore.getValue().entities).length);
    //     console.log("Issue store", Object.values(issueStore.getValue().entities).length);
    //     console.log("Project store", Object.values(projectStore.getValue().entities).length);
    //     console.log("DELAY", DELAY);

    //     await delay(DELAY);
    //     //await selectPersistStateInit().pipe(take(1)).toPromise();

    //     console.log("User store", Object.values(userStore.getValue().entities).length);
    //     console.log("Issue store", Object.values(issueStore.getValue().entities).length);
    //     console.log("Project store", Object.values(projectStore.getValue().entities).length);
    //   },
    //   deps: [IssueStore, UserStore, ProjectStore],
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: 'SOCKET_IO_ADDRESS', useValue: `${environment.apiUrl}` },
    { provide: NZ_ICONS, useValue: icons },
    AppEventsService,
    ClientMessagesService, ,
    {
      provide: ISSUE_CREATION, useClass: IssueCreationHandler,
    },
    {
      provide: APPOINTMENT_REMOTE_GET, useClass: AppointmentRemoteGetByUsersHandler
    },
    { provide: DATA_SOURCE, useClass: UserDataSource, multi: true },
    { provide: DATA_SOURCE, useClass: PlayersDataSource, multi: true },
    { provide: DATA_SOURCE, useClass: TeamsDataSource, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /* constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  } */
}
function delay(ms: number) {
  return new Promise((res) => {
    setTimeout(res, ms)
  })
}
