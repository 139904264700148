import { Injectable } from "@angular/core";
import { IID_ASSURANCE_TICKET } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDassuranceTicketStore } from "./iidAssuranceTicket.store";

@Injectable({
  providedIn: 'root'
})
export class IIDassuranceTicketQuery extends AbstractInterfaceDataQuery<IID_ASSURANCE_TICKET> {

  constructor(protected store: IIDassuranceTicketStore) {
    super(store);
  }
}
