import { Injectable } from "@angular/core";
import { EntityNameType } from "@common/interfaces/entityInteraction";
import { ID } from "@common/interfaces/id";
import { QueryEntity } from "@datorama/akita";
import { EntityInteractionState, EntityInteractionStore } from "./entityInteraction.store";

@Injectable({
  providedIn: 'root'
})
export class EntityInteractionQuery extends QueryEntity<EntityInteractionState> {
  all$ = this.selectAll();

  getByIssueId(id: ID) {
    return this.getAll({
      filterBy: entity => entity.issueId === id
    });
  }
  selectByIssueId$(id: ID) {
    return this.selectAll({
      filterBy: entity => entity.issueId === id
    });
  }

  getByKeyProps(userId: ID, issueId: ID, entityName: EntityNameType) {
    return this.getEntity(this.store.generateID(userId, issueId, entityName))
  }

  constructor(protected store: EntityInteractionStore) {
    super(store);
  }
}
