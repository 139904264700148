import { Injectable } from "@angular/core";
import { SysMessage } from "@common/interfaces/sysMessage";
import { Topics } from "@common/interfaces/topics";
import { AppService, MAINTENANCE_LOCALSTORAGE_KEY } from "@ep-om/app.service";
import { SocketIoService } from "@ep-om/core/services/socket-io.service";
import { Subject } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { NotificationsService } from "../state/notifications/notifications.service";

@Injectable({
  providedIn: 'root',
})
export class SysMessageService {

  private _sysMessages$ = new Subject<SysMessage>();
  public sysMessages$ = this._sysMessages$.asObservable();

  constructor(
    private socketIoService: SocketIoService,
    private appService: AppService,
    private notifService: NotificationsService,
  ) {
    this.ManageMaintenance();

    this.manageMultipleTabs();
    this.getNews();
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.socketIoService.listenEntity<SysMessage>(Topics.SYSMESSAGES)),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe()
    ).subscribe((msg) => {
      console.log('[SYSMSG] - new message:', msg);
      this._sysMessages$.next(msg.data as unknown as SysMessage);
    });

  }

  sendMessage(topic: Topics, object: string, body: any, callback?: (response: any) => void) {
    if (!callback) {
      return this.socketIoService.sockets[topic].emit(object, body);
    }
    return this.socketIoService.sockets[topic].emit(object, body, callback);
  }

  private ManageMaintenance() {
    this._sysMessages$.pipe(
      filter(sysMessage => sysMessage.topic === 'MAINTENANCE')
    ).subscribe((maintenance: any) => {
      this.appService.setMaintenance(maintenance.payload.isMaintenance);
    })
  }



  public getMaintenance(): boolean {
    let inMaintenance = false;
    const temp = localStorage.getItem(MAINTENANCE_LOCALSTORAGE_KEY);
    if (temp === 'true' || temp === 'false') {
      inMaintenance = temp === 'true' || false;
    }
    return inMaintenance;
  }

  private manageMultipleTabs() {
    this._sysMessages$.pipe(
      filter(sysMessage => sysMessage.topic === 'CLIENT/INFO')
    ).subscribe(info => {
      console.log('[SYSMSG] - main tab', info.payload.mainClient)
      this.appService.updateMaintab(info.payload.mainClient);
    })
  }

  private getNews() {
    this.sysMessages$.pipe(
      filter(sysMessage => sysMessage.topic === 'NEWS/PROJECT'),
    ).subscribe(
      (info) => {
        this.notifService.add(info.payload);
      },
    );
  }
}
