import { Injectable, Injector } from "@angular/core";
import { SysMessageService } from "./sysMessage.service";
import { SysMessage } from "@common/interfaces/sysMessage";
import { v4 } from "uuid";
import { filter } from "rxjs/operators";
import { Topics } from "@common/interfaces/topics";
import { AuthService } from "../auth/auth.service";
import { UiService } from "../state/ui/ui.service";
import { ProjectScopeService } from "../state/projectScope/projectScope.service";
import { LastUpdateService } from "../state/lastUpdate/lastUpdate.service";
import { AppEventsService } from "./appEvents.service";
import { IIssueTypeInterface, getInterfaceNameKeyFromValue } from "@common/interfaces/issueTypeInterface";
import { interfaceDataQueryMap } from "../state/interfaceDataMapping";
import { IssueTypeInterfaceQuery } from "../state/issueTypeInterface/issueTypeInterface.query";
import { IssueQuery } from "../state/issue/issue.query";
import { ActionService } from "../state/action/action.service";

@Injectable({
  providedIn: 'root',
})
export class ClientMessagesService {

  constructor(
    public sms: SysMessageService,
    public auth: AuthService,
    public uiService: UiService,
    public projectScopeService: ProjectScopeService,
    public lastUpdateService: LastUpdateService,
    public appEventService: AppEventsService,
    public actionService: ActionService,
    private _injector: Injector,
    private _typeInterfaceQuery: IssueTypeInterfaceQuery,
    private _issueQuery: IssueQuery,
  ) {

    console.log("[CMS] constructor");

    this.sms.sysMessages$.pipe(filter(sysMessage => sysMessage.topic === 'REQUEST')).subscribe(async (request: SysMessage) => {
      this.sms.sendMessage(Topics.SYSMESSAGES, "response", await this.requestManager(request));
    });
    this.sms.sysMessages$.pipe(filter(sysMessage => sysMessage.topic === 'MESSAGE')).subscribe(async (msg: SysMessage) => {
      await this.messageManager(msg);
    });
  }


  async requestManager(rq: SysMessage): Promise<SysMessage> {
    let res: SysMessage = { ...rq, requestId: rq.id, id: v4() };
    const cmd = rq.payload.cmd ? rq.payload.cmd.toUpperCase() : null;
    switch (cmd) {
      case "GET-UISTORE":
        res.payload.returnValue = this.uiService.query.getValue();
        break;
      case "GET-ACTIONSTORE":
        res.payload.returnValue = this.actionService.query.getValue();
        break;
      case "GET-PROJECTSCOPE":
        res.payload.returnValue = this.projectScopeService.query.getValue();
        break;
      case "GET-LASTUPDATE":
        res.payload.returnValue = this.lastUpdateService.query.getValue();
        break;
      case "GET-APPEVENTS":
        res.payload.returnValue = this.appEventService.events;
        break;
      case "GET-ISSUE-DATA": {
        if (!rq.payload.data) {
          res.payload.returnValue = { error: 'missing data field on payload' };
        } else {
          res.payload.returnValue = this.getIssue(rq.payload.data);
        }
      }
        break;
      default:
        res.payload.returnValue = { error: `[${cmd}] - REQUEST sconosciuta` };
        console.error("[CMS] client REQUEST sconosciuta", cmd || rq);
        break;
    }
    return res;
  }

  async messageManager(msg: SysMessage): Promise<void> {
    const cmd = msg.payload.cmd ? msg.payload.cmd.toUpperCase() : null;
    switch (msg.payload.cmd) {
      case "REFRESH": window.location.reload(); break;
      case "LOGOUT": await this.auth.logout(); break;
      default:
        console.error("[CMS] client MESSAGE sconosciuto", cmd || msg);
        break;
    }
  }

  getIssue(issueId: string) {
    const issue = this._issueQuery.getEntity(issueId);
    const interfaces: IIssueTypeInterface[] = this._typeInterfaceQuery.getByIssueTypeId(issue.typeId);

    const interfacesData: { [key: string]: any[] } = {}
    for (const int of interfaces) {
      const query = this._injector.get(interfaceDataQueryMap[int.interfaceName])
      const interfaces = query.getByIssueId(issueId);
      interfacesData[getInterfaceNameKeyFromValue(int.interfaceName)] = interfaces;
    }

    return {
      ...issue,
      ...interfacesData,
    }

  }



}
