import Color from "color";

export function colorContrast(hexColor: string) {
  if (hexColor && hexColor.startsWith("#")) {

    // map RGB values to variables
    const r = parseInt(hexColor.substr(1, 2), 16),
      g = parseInt(hexColor.substr(3, 2), 16),
      b = parseInt(hexColor.substr(5, 2), 16);

    // calculate contrast of color (standard grayscale algorithmic formula)
    const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;


    return (contrast >= 135) ? '#333333' : '#eeeeee';
  }
}

export function colorText(hexColor: string): string {
  return hexColor ? new Color(hexColor).isDark() ? 'white' : 'black' : 'black';
}
