import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export const EventNames = ['load','unload','beforeunload','appinstalled','beforeinstallprompt','offline','online','pagehide','pageshow','popstate','rejectionhandled','resize','storage','unhandledrejection' ] as const;
export type EventName = typeof EventNames[number];
export interface AppEvent { ts:Date, eventName:EventName, $event:any }
const MAX_EVENTS_COUNT = 100;

Injectable({
  providedIn: 'root',
})
export class AppEventsService {

  private _event$ = new Subject<AppEvent>();
  public event$ = this._event$.asObservable();
  public events:AppEvent[] = [];

  constructor() {

    console.log("[AES] constructor");

    for(const eventName of EventNames) {
      console.log("[AES] aggiungo l'event listener",eventName);
      try {
        window.addEventListener(eventName, ($event) => { 
          this._event$.next({ ts:new Date(), eventName, $event }); 
        });
      }
      catch(err) {
        console.error("[AES] aggiunta dell'event listener",eventName,"fallita",err.message || err);
      }
    }
    
    this.event$.subscribe(e=>{
      this.events.push(e);
      if(this.events.length>MAX_EVENTS_COUNT) this.events.shift();
    })
  }

}
