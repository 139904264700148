import { Injectable } from '@angular/core';
import { IID_OL_DEACTIVATION } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolDeactivationQuery } from './iidOlDeactivation.query';
import { IIDolDeactivationStore } from './iidOlDeactivation.store';

@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationService extends CrudService<IID_OL_DEACTIVATION, IIDolDeactivationStore, IIDolDeactivationQuery> {
  constructor(
    protected store: IIDolDeactivationStore,
    public query: IIDolDeactivationQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OLO_DEACTIVATION,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
