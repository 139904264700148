import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_EP_RISPOSTA } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDEpRispostaState extends EntityState<IID_EP_RISPOSTA, ID> { }

@Injectable({
    providedIn: 'root'
  })
  @StoreConfig({ name: Topics.IID_EP_RISPOSTA })
  export class IIDEpRispostaStore extends EntityStore<IIDEpRispostaState> {
    constructor() {
      super([]);
    }
  }
  