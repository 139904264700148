import { Injectable } from "@angular/core";
import { IID_ISSUE_LINK } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDIssueLinkStore } from "./iidIssueLink.store";

@Injectable({
  providedIn: 'root'
})
export class IIDIssueLinkQuery extends AbstractInterfaceDataQuery<IID_ISSUE_LINK> {
  constructor(protected store: IIDIssueLinkStore) {
    super(store);
  }
}
