import { Injectable } from "@angular/core";
import { IIssueStateWorkflow } from "@common/interfaces/issueStateWorkflow";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IssueStateWorkflowState extends EntityState<IIssueStateWorkflow, ID> { }

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: Topics.ISSUE_STATE_WORKFLOWS })
export class IssueStateWorkflowStore extends EntityStore<IssueStateWorkflowState> {
    constructor() {
        super([]);
    }
}
