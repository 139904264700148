import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { MetaInterfacesState, MetaInterfacesStore } from "./metaInterfaces.store";
import { InterfaceName, InterfaceNameKey, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MetaInterfacesQuery extends QueryEntity<MetaInterfacesState> {
  constructor(protected store: MetaInterfacesStore) {
    super(store)
  }

  getInterfaces(): {key: string, value: string}[] {
    return this.getAll()
      .map(meta => ({ key: meta.name, value: meta.tableName }))
      .concat(
        Object.entries(InterfaceName).map(([key, value]) => ({key, value}))
      )
      .sort((a, b) => a.key.localeCompare(b.key));
  }

  getInterfaceNameByKey(key: InterfaceNameKey | string & {}): {key: string, value: string} {
    return this.getInterfaces().find(int => int.key === key);
  }

  getInterfaceNameByValue(val: InterfaceNameValue | string & {}): {key: string, value: string} {
    return this.getInterfaces().find(int => int.value === val);
  }

  getInterfaceByValue(val: InterfaceNameValue | string & {}) {
    console.log('getting interface by val', val )
    const found = this.getAll({filterBy: [
      entity => entity.tableName === val
    ]});
    if (!found.length) {
      return null;
    }
    return found[0];
  }

  selectInterfaceByValue$(val: InterfaceNameValue | string & {}) {
    return this.selectAll({filterBy: [
      entity => entity.tableName === val
    ]}).pipe(
      map(i => {
        if (!i.length) {
          return null;
        }
        return i[0];
      })
    )
  }

}
