import { Injectable } from '@angular/core';
import { IAppointment } from '@common/interfaces/appointment';
import { ID } from '@common/interfaces/id';
import { Topics } from '@common/interfaces/topics';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface AppointmentState extends EntityState<IAppointment, ID> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Topics.APPOINTMENT })
export class AppointmenteStore extends EntityStore<AppointmentState> {
  constructor() {
    super([]);
  }
}
