import { Injectable } from "@angular/core";
import { IID_EP_RISPOSTA } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDEpRispostaQuery } from "./iidEPRisposta.query";
import { IIDEpRispostaStore } from "./iidEPRisposta.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDEpRispostaService extends CrudService<IID_EP_RISPOSTA, IIDEpRispostaStore, IIDEpRispostaQuery> {
    constructor(
      protected store: IIDEpRispostaStore,
      public query: IIDEpRispostaQuery,
      protected actionService: ActionService,
      protected updateStrategy: ProjectEntityStoreStrategy,
    ) {
      super(
        Topics.IID_EP_RISPOSTA,
        store,
        query,
        actionService,
        updateStrategy
      );
    }
  }
