<div  id="app-frame">
  
  <j-snow *ngIf="this.letItSnow"></j-snow>

  <router-outlet *ngIf="this.appService.isVersionOk; else elseBlock"></router-outlet>

  <ng-template #elseBlock>
    <div class="w-full flex justify-center">
      <div class="mt-4 flex flex-row updatePanel">
        <div class="logo-column pr-4">
          <img src="assets/customizable/logo_login.png" class="logo">
        </div>
        <div class="flex flex-col justify-between">
          <div class="mb-0 font-medium gray">Aggiornamento</div>
          <div class="mb-2 text-2xl font-medium black">{{ this.appService.SETTINGS.appTitle }}</div>
          <nz-progress [nzPercent]="this.updProgress" [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }" [nzShowInfo]="false"></nz-progress>
          <div class="mt-4"> Backend Version <b>{{ this.appService.BACKEND_VERSION?.BACKEND }}</b></div>
          <div> API Version <b>{{ this.appService.BACKEND_VERSION?.API }}</b></div>
          <div> Web App Version <b>{{ this.appService.FRONTEND_VERSION.toString() }}</b></div>
          <div> Minimum Web App Version <b>{{ this.appService.BACKEND_VERSION?.MIN_FRONTEND_VERSION }}</b></div>
        </div>
      </div>
    </div>
  </ng-template>

</div>

