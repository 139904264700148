import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '@common/interfaces/user';
import { FieldArrayType } from '@ngx-formly/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'repeat',
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.scss']
})
export class RepeatComponent extends FieldArrayType implements OnInit {
  collapsed = true;
  ngOnInit(): void {}

  add() {
    if(this.field.props.disabled) {
      return;
    }
    if(this.field.props.initialModel){
      console.log('this.field.props.initialModel:', this.field.props.initialModel)
      super.add(this.model?.length || 1, this.field.props.initialModel);
    } else {
      super.add()
    }
    this.collapsed = false;
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  remove(i) {
    if(this.field.props.disabled) {
      return;
    }
    super.remove(i)
  }
  
}
