import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IID_NETWORK_ITEMS, IID_NETWORK_ITEMS_REMOVE_EVIDENCE, IID_NETWORK_ITEMS_REMOVE_INTERVENTION, IID_NETWORK_ITEMS_REMOVE_TEST, IID_NETWORK_ITEMS_RESPONSE_ACTION, IID_NETWORK_ITEMS_SEND_EVIDENCE, IID_NETWORK_ITEMS_SEND_INTERVENTION, IID_NETWORK_ITEMS_SEND_TEST } from '@common/interfaces/interfaceData';
import { IResource } from '@common/interfaces/resource';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDNetworkItemsQuery } from './iidNetworkItems.query';
import { IIDNetworkItemsStore } from './iidNetworkItems.store';

@Injectable({
  providedIn: 'root'
})
export class IIDNetworkItemsService extends CrudService<IID_NETWORK_ITEMS, IIDNetworkItemsStore, IIDNetworkItemsQuery> {

  constructor(
    protected store: IIDNetworkItemsStore,
    public query: IIDNetworkItemsQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy,
    private httpClient: HttpClient,
  ) {
    super(
      Topics.IID_NETWORK_ITEMS,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  async getNetIntervetionFreeResources(issueId: string, resourceId: string, interventionType: string) {
    return this.httpClient.get<Partial<IResource>[] | { error: string }>('/api/iid_network_items/intervention/freeResourcesFromGisfo', { params: { issueId, resourceId, interventionType } }).toPromise();
  }

  async netIntervetionVerifyFreeResource(issueId: string, resourceBrokenId: string, resourceFreeGisfoId: string, interventionType: string) {
    return this.httpClient.post<{ status: 'success' | 'error', error: string }>('/api/iid_network_items/intervention/verifyfreeResourceFromGisfo', { issueId, resourceBrokenId, resourceFreeGisfoId, interventionType }).toPromise();
  }

  async saveNetEvidence(dto: IID_NETWORK_ITEMS_SEND_EVIDENCE) {
    return this.httpClient.post<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/evidence/saveEvidenceAndSendToGisfo', dto).toPromise();
  }

  async saveNetTest(dto: IID_NETWORK_ITEMS_SEND_TEST) {
    return this.httpClient.post<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/test/saveTestAndSendToGisfo', dto).toPromise();
  }

  async saveNetIntervention(dto: IID_NETWORK_ITEMS_SEND_INTERVENTION) {
    return this.httpClient.post<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/intervention/saveInterventionAndSendToGisfo', dto).toPromise();
  }

  async removeNetEvidence(dto: IID_NETWORK_ITEMS_REMOVE_EVIDENCE) {
    return this.httpClient.delete<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/evidence/removeEvidence', { body: dto }).toPromise();
  }

  async removeNetTest(dto: IID_NETWORK_ITEMS_REMOVE_TEST) {
    return this.httpClient.delete<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/test/removeTest', { body: dto }).toPromise();
  }

  async removeNetIntervention(dto: IID_NETWORK_ITEMS_REMOVE_INTERVENTION) {
    return this.httpClient.delete<IID_NETWORK_ITEMS_RESPONSE_ACTION>('/api/iid_network_items/intervention/removeIntervention/', { body: dto }).toPromise();
  }

}
