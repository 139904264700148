import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_OL_RIFERIMENTI_UI } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDolRiferimentiUiState extends EntityState<IID_OL_RIFERIMENTI_UI, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_RIFERIMENTI_UI })
export class IIDolRiferimentiUiStore extends EntityStore<IIDolRiferimentiUiState> {
  constructor() {
    super([]);
  }
}
