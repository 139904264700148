import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_EP_SUPPORT, IID_GR_RESOURCES } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIDGrResourcesState extends EntityState<IID_GR_RESOURCES, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_GR_RESOURCES })
export class IIDGrResourcesStore extends EntityStore<IIDGrResourcesState> {

  constructor() {
    super([]);
  }

}
