import { Injectable } from "@angular/core";
import { IID_OL_SERVIZIO_AGGIUNTIVO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolServizioAggiuntivoStore } from "./iidOlServizioAggiuntivo.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolServizioAggiuntivoQuery extends AbstractInterfaceDataQuery<IID_OL_SERVIZIO_AGGIUNTIVO> {
  constructor(protected store: IIDolServizioAggiuntivoStore) {
    super(store);
  }
}
