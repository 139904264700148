import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatISO } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'om-date-input',
  templateUrl: './om-date-input.component.html',
  styleUrls: ['./om-date-input.component.scss']
})
export class OmDateInputComponent {

  @Input() control: FormControl = new FormControl();
  @Input() disabled: boolean = false;
  @Input() placeholder = '';

  @Input() label: string;
  @Input() icon: string;

  @Input() dateFormat = '';
  @Input() onlyFuture = false;
  @Input() showTime: (object | boolean) = false;
  @Input() allowClear: boolean = true;

  isRequired$ = new BehaviorSubject<boolean>(false);
  _control = new FormControl();

  constructor() { }

  ngOnInit(): void {
    if (this.control.hasValidator(Validators.required)) {
      this.isRequired$.next(true);
      this._control.setValidators(Validators.required);
    }
    this._control.setValue(this.control.value);
    this._control.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(value => {
      const dateString = value ? formatISO(value, { representation: this.showTime ? 'complete' : 'date' }) : null;
      this.control.setValue(dateString);
      this.control.markAsDirty();
    });
    this.control.registerOnChange(value => this._control.setValue(value, {emitEvent: false}))
  }

  disabledDate = (currDate: Date) => {
    if (this.onlyFuture === true) {   
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return date.getTime() > currDate.getTime();
    }
    return false;
  }
}
