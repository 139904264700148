import { Inject, Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IWorkflow } from "@common/interfaces/workflow";
import { ID } from '@common/interfaces/id';
import { ActiveState, Constructor, EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { STORAGE_INFO_KEY, StorageInfo } from "@ep-om/app.module";
import { LocalStorageService } from "@ep-om/project/service/localstorage.service";

export interface WorkflowState extends EntityState<IWorkflow, ID>, ActiveState { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.WORKFLOWS })
export class WorkflowStore extends EntityStore<WorkflowState> {

  storageInfoInitialized = false;
  constructor(
    private storageService: LocalStorageService,
    @Inject(STORAGE_INFO_KEY) private storageInfoKey: string,
  ) {
    super([]);
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        currentInfo = currentInfo || [];
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: false })
        }
        return currentInfo;
      }
    )
  }

  upsertMany(entities: IWorkflow[], options?: { baseClass?: Constructor; loading?: boolean; }): void {
    super.upsertMany(entities, options);
    if (this.storageInfoInitialized) {
      return;
    }
    this.storageService.updateInTransaction<StorageInfo[]>(
      this.storageInfoKey,
      (currentInfo) => {
        const storeIdx = currentInfo.findIndex(store => store.name === this.storeName);
        if (storeIdx === -1) {
          currentInfo.push({ name: this.storeName, isMeta: false, idKey: this.idKey, hasValue: true })
        } else {
          currentInfo[storeIdx].hasValue = true;
        }
        return currentInfo;
      }
    )
    this.storageInfoInitialized = true;
  }

  reset(): void {
    super.reset();
    this.storageInfoInitialized = false;
  }
}
