import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  @Input() label: string;
  @Input() icon: string;
  @Input() preview = false;
  @Input() control;
  @Input() readonly;
  isRequired$ = new BehaviorSubject<boolean>(false);
  toggle: boolean = false;
  color = ''

  changeColor(event: string) {
    this.control.setValue(event);
    this.control.markAsDirty();
  }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl('');
    this.isRequired$.next(this.control.hasValidator(Validators.required))
  }

}
