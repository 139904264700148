import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'om-label',
  templateUrl: './om-label.component.html',
  styleUrls: ['./om-label.component.scss']
})
export class OmLabelComponent implements OnInit {
  
  @Input() text: string;
  @Input() icon: string;

  @Input() isRequired: boolean;

  constructor() { }

  ngOnInit(): void { }

}
