import { Injectable } from "@angular/core";
import { IID_GENERIC_APPOINTMENT } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IID_Generic_AppointmentStore } from "./genericAppointment.store";

@Injectable({
  providedIn: 'root'
})
export class IID_Generic_AppointmentQuery extends AbstractInterfaceDataQuery<IID_GENERIC_APPOINTMENT> {

  constructor(protected store: IID_Generic_AppointmentStore) {
    super(store);
  }

  getOneByIssueId(issueId: string) {
    return this.getAll({
      filterBy: appuntamento => appuntamento.issueId === issueId
    })?.[0]
  }

}
