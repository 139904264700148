import { Injectable } from '@angular/core';
import { IID_ISSUE_LINKED } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDIssueLinkedStore } from './iidIssueLinked.store';
import { IIDIssueLinkedQuery } from './iidIssueLinked.query';
@Injectable({
  providedIn: 'root'
})
export class IIDIssueLinkedService extends CrudService<IID_ISSUE_LINKED, IIDIssueLinkedStore, IIDIssueLinkedQuery> {

  constructor(
    protected store: IIDIssueLinkedStore,
    public query: IIDIssueLinkedQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_ISSUE_LINKED,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }
}
