import { Injectable } from "@angular/core";
import { IID_OL_OLOREQUEST_OF } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolOloRequestOFStore } from "./iidOlOloRequestOF.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolOloRequestOFQuery extends AbstractInterfaceDataQuery<IID_OL_OLOREQUEST_OF> {
  constructor(protected store: IIDolOloRequestOFStore) {
    super(store);
  }
}
