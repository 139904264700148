import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { IID_OL_SUPPORTO } from "@common/interfaces/interfaceData";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface IIdOlSupportoState extends EntityState<IID_OL_SUPPORTO, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_SUPPORTO })
export class IIDolSupportoStore extends EntityStore<IIdOlSupportoState> {
  constructor() {
    super([]);
  }
}
