import { Injectable } from "@angular/core";
import { IID_OL_CONTRATTO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolContrattoStore } from "./iidOlContratto.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolContrattoQuery extends AbstractInterfaceDataQuery<IID_OL_CONTRATTO> {

  constructor(protected store: IIDolContrattoStore) {
    super(store);
  }
}
