export enum AppFeatures {
    ARCHIVE = "archive",
    HISTORY = "history",
    EXPORT = "export",
    FORGOT_PASSWORD = "forgot_password",
    ATTACHMENTS = 'attachments',
    DOCUMENTATION = 'documentation',
    OLO_REQ_LOG = 'olo_req_log',
    REPORT_FTTH = 'report_ftth',
    TAGS = 'tags',
    REGISTER = 'register',
    EXTERNAL_TASKS = 'external_tasks',
    DASHBOARD = 'dashboard',
    I18N = 'i18n',
    MILESTONE = 'milestone',
    CALENDAR = "calendar",
    MAP = "map",
};

export type DisplayType = "DISABLED" | "OFFLINE" | "ONLINE";

export type FeatureEnvironmentType = "OFFLINE" | "ONLINE";

export const FeatureEnvironmentAssociation: { [P in AppFeatures]: FeatureEnvironmentType } = {
    archive: "ONLINE",
    history: "ONLINE",
    documentation: "ONLINE",
    attachments: "ONLINE",
    export: "OFFLINE",
    forgot_password: "ONLINE",
    olo_req_log: "ONLINE",
    report_ftth: "ONLINE",
    tags: "OFFLINE",
    register: "ONLINE",
    external_tasks: "OFFLINE",
    dashboard: "ONLINE",
    i18n: "ONLINE",
    milestone: "ONLINE",
    calendar: "ONLINE",
    map: "ONLINE",
}

export type FeatureDisplay = {
    [P in AppFeatures]: DisplayType;
};

export const defaultFeatureDisplay: FeatureDisplay = {
    archive: "DISABLED",
    history: "DISABLED",
    attachments: "DISABLED",
    export: "DISABLED",
    documentation: "DISABLED",
    forgot_password: "DISABLED",
    olo_req_log: "DISABLED",
    report_ftth: "DISABLED",
    tags: "DISABLED",
    register: "DISABLED",
    external_tasks: "DISABLED",
    dashboard: "DISABLED",
    i18n: "DISABLED",
    milestone: "DISABLED",
    calendar: "DISABLED",
    map: "DISABLED",
}
