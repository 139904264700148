import { EntityState, EntityStore, StoreConfig, ActiveState, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Action } from './action.model';

export interface ActionState {
  buffer: Action[],
  queue: Action[],
  inTransfer: Action
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'action' })
export class ActionStore extends Store<ActionState> {
  constructor() {
    super({
      buffer: [],
      queue: [],
      inTransfer: null
    });
  }
}
