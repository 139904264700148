<div class="w-full flex flex-col bg-gray-100 pt-1 px-1 mb-2">

    <div class="w-full flex justify-between mb-1">
        <div class="text-gray-600"> <j-button [icon]="this.collapsed ? 'next' : 'expand' " className="btn-empty mr-2" (click)="toggleCollapsed()"></j-button><b>{{ to.title }}</b></div>
    </div>

    <div [style.display]="this.collapsed ? 'none' : null" class="w-full flex justify-between p-1 bg-white mb-1">
        <ng-container #fieldComponent></ng-container>
    </div>

</div>