import { IIssue, IIssueAgeing } from '../interfaces/issue';

export class IssueUtil {
  static isNearDueDate(issue: IIssue) {
    if (!issue.dueDate) return false;
    const today = new Date();
    const dueDate = new Date(issue.dueDate);
    return (today.getTime() - dueDate.getTime()) > -432000000 // 5 giorni
  }
  /* static getIssueTypeIcon(issueTypeId: ID): string {
    return issueTypeId;
  } */

  static getRandomId(): string {
    return `${Math.ceil(Math.random() * 8000)}`;
  }

  static searchString(str: string, searchString: string): boolean {
    str = str ?? '';
    searchString = searchString ?? '';
    return str.trim().toLowerCase().includes(searchString.trim().toLowerCase());
  }

  static calcCurrentAgeing(ageing: IIssueAgeing): number {
    let currentAgeing: number;
    if (ageing.enable) {
      const differenceDays = Math.floor((new Date().getTime() - new Date(ageing.date).getTime()) / (1000 * 60 * 60 * 24));
      currentAgeing = ageing.partialAgeing + differenceDays;
    } else {
      currentAgeing = ageing.partialAgeing;
    }
    return currentAgeing;
  }
}
