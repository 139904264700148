import { Injectable } from "@angular/core";
import { IID_OL_DEACTIVATION } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolDeactivationStore } from "./iidOlDeactivation.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationQuery extends AbstractInterfaceDataQuery<IID_OL_DEACTIVATION> {

  constructor(protected store: IIDolDeactivationStore) {
    super(store);
  }
}
