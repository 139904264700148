import { Injectable } from "@angular/core";
import { IID_OL_CLIENTE } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolClienteStore } from "./iidOlCliente.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolClienteQuery extends AbstractInterfaceDataQuery<IID_OL_CLIENTE> {
  constructor(protected store: IIDolClienteStore) {
    super(store);
  }
}
