import { Injectable } from "@angular/core";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { IID_MAIL_GTW_SOURCE } from "@common/interfaces/interfaceData";

export interface IIdMailGtwState extends EntityState<IID_MAIL_GTW_SOURCE, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_MAIL_GTW_SOURCE })
export class IIDMailGtwStore extends EntityStore<IIdMailGtwState> {
  constructor() {
    super([]);
  }
}
