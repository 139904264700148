import { Injectable } from "@angular/core";
import { IID_EP_RICHIESTA_PERMESSO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDEpRichiestaStore } from "./iidEPRichiesta.store";

@Injectable({
    providedIn: 'root'
  })
  export class IIDEpRichiestaQuery extends AbstractInterfaceDataQuery<IID_EP_RICHIESTA_PERMESSO> {
  
    constructor(protected store: IIDEpRichiestaStore) {
      super(store);
    }
  }