import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_OK_OF } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolEspletatoOkOFQuery } from "./espletatoOkOF.query";
import { IIDolEspletatoOkOFStore } from "./espletatoOkOF.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoOkOFService extends CrudService<IID_OL_ESPLETATO_OK_OF, IIDolEspletatoOkOFStore, IIDolEspletatoOkOFQuery> {
  constructor(
    protected store: IIDolEspletatoOkOFStore,
    public query: IIDolEspletatoOkOFQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ESPLETATO_OK_OF,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
