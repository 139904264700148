import { Injectable } from "@angular/core";
import { IID_OL_ANNULLAMENTO} from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolAnnullatoStore } from "./annullato.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAnnullatoQuery extends AbstractInterfaceDataQuery<IID_OL_ANNULLAMENTO> {

  constructor(protected store: IIDolAnnullatoStore) {
    super(store);
  }
}
