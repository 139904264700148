import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_CHECKLIST } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDolChecklistState extends EntityState<IID_OL_CHECKLIST, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_CHECKLIST })
export class IIDolChecklistStore extends EntityStore<IIDolChecklistState> {
  constructor() {
    super([]);
  }
}
