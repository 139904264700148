import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_OL_ESPLETATO_OK_OF } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IIDolEspletatoOkOFState extends EntityState<IID_OL_ESPLETATO_OK_OF, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_OL_ESPLETATO_OK_OF })
export class IIDolEspletatoOkOFStore extends EntityStore<IIDolEspletatoOkOFState> {
  constructor() {
    super([]);
  }
}
