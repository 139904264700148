import { Injectable } from "@angular/core";
import { IID_OL_RISPOSTA_SOLLECITO } from "@common/interfaces/interfaceData";
import { AbstractInterfaceDataQuery } from "../interfaceDataModel";
import { IIDolRispostaSollecitoStore } from "./iidOlRispostaSollecito.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolRispostaSollecitoQuery extends AbstractInterfaceDataQuery<IID_OL_RISPOSTA_SOLLECITO> {
  constructor(protected store: IIDolRispostaSollecitoStore) {
    super(store);
  }
}
