import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IID_GENERIC_APPOINTMENT } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { EntityState, StoreConfig, EntityStore } from "@datorama/akita";

export interface IID_Generic_AppointmentState extends EntityState<IID_GENERIC_APPOINTMENT, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.IID_GENERIC_APPOINTMENT })
export class IID_Generic_AppointmentStore extends EntityStore<IID_Generic_AppointmentState> {

  constructor() {
    super([]);
  }

}
