import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'time-picker-formly',
  templateUrl: './time-picker-formly.component.html',
  styleUrls: ['./time-picker-formly.component.scss'],
})
export class TimePickerFormlyComponent extends FieldType implements OnInit {

  ngOnInit(): void {
    if (this.props.required) {
      this.formControl.setValidators(Validators.required);
    }
  }

}
