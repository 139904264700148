<div class="select-container" [ngStyle]="_width">
  <label class="label" *ngIf="label && !hidden">
    {{ label }}
  </label>
  <nz-select *ngIf="!hidden" class="w-full" [nzDisabled]="disabled" [formControl]="control">
    <nz-option *ngFor="let item of selectOptions" 
      [nzValue]="item.value" 
      [nzLabel]="item.label" 
    >
    </nz-option>
  </nz-select>
</div>
